import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ArrowNext from 'assets/svg/arrow-right.svg';
import ArrowPrev from 'assets/svg/arrow-left.svg';
import LinkedIn from 'assets/svg/linkedin-icon.svg';
import { CTALink, MarkDown } from 'components';
import { PROFILE_DETAIL_PROP, TEAM_MEMBER_LIST } from 'types';

function ProfileDetail({ people }: PROFILE_DETAIL_PROP) {
  return (
    <div className="meetourteam">
      <Swiper
        slidesPerView={1}
        speed={1200}
        modules={[Navigation, Pagination]}
        mousewheel
        loop
        navigation={{
          prevEl: '.prev-btn',
          nextEl: '.next-btn',
        }}
        pagination={{
          type: 'fraction',
          el: '.people-pagination',
        }}
      >
        {people?.length > 0 &&
          people.map((item: TEAM_MEMBER_LIST) => {
            const {
              name = '',
              position = '',
              description = { data: '' },
              image = null,
              socialmediaLink = '',
            } = item.person;

            const Image =
              image && image?.localFile
                ? getImage(image?.localFile?.childImageSharp?.gatsbyImageData)
                : null;
            return (
              <SwiperSlide key={name}>
                <div className="meetourteam-section-wrapper">
                  {Image && (
                    <div className="meetourteam-section-peopleimg">
                      <GatsbyImage
                        image={Image}
                        alt={image?.alternativeText || name}
                      />
                    </div>
                  )}
                  <div className="meetourteam-section-aboutpeople">
                    <p className="meetourteam-section-aboutpeople-name">
                      {name && name}
                      {socialmediaLink && (
                        <CTALink ctaLink={socialmediaLink}>
                          <LinkedIn />
                        </CTALink>
                      )}
                    </p>
                    {position && (
                      <p className="meetourteam-section-aboutpeople-position">
                        {position}
                      </p>
                    )}
                    {description?.data && (
                      <MarkDown className="meetourteam-section-aboutpeople-desc">
                        {description?.data}
                      </MarkDown>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        <div className="people-navigation-wrapper">
          <div className="people-navigation">
            <div className="prev-btn">
              <ArrowPrev />
            </div>
            <div className="people-pagination" />
            <div className="next-btn">
              <ArrowNext />
            </div>
          </div>
        </div>
      </Swiper>
    </div>
  );
}

export { ProfileDetail };
