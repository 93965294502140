import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CTALink, MarkDown } from 'components';
import { CASE_STUDY_SLIDER_PROP, CASE_STUDY_SLIDER } from 'types';

function OurWorkSlider({ caseStudySlider, ctaLink }: CASE_STUDY_SLIDER_PROP) {
  return (
    <div className="our-work-card-wrapper">
      <Swiper
        slidesPerView={1.2}
        speed={1200}
        modules={[Autoplay]}
        mousewheel
        spaceBetween={15}
        loop
        breakpoints={{
          767: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
      >
        {caseStudySlider?.length > 0 &&
          caseStudySlider.map((item: CASE_STUDY_SLIDER) => {
            const {
              title = '',
              sliderImage = null,
              slug = '',
              shortDescription = {
                data: {
                  shortDescription: '',
                },
              },
            } = item.case_study;
            const Image =
              sliderImage && sliderImage?.localFile
                ? getImage(
                    sliderImage?.localFile?.childImageSharp?.gatsbyImageData
                  )
                : null;
            return (
              <SwiperSlide key={title}>
                {title && shortDescription && (
                  <CTALink ctaLink={`${ctaLink}/${slug}`}>
                    <div className="our-work-card">
                      <div className="our-work-card-details">
                        <div className="our-work-card-details-wrapper">
                          {Image && (
                            <div className="our-work-card-img">
                              <GatsbyImage
                                image={Image}
                                alt={sliderImage?.alternativeText || title}
                              />
                            </div>
                          )}
                        </div>
                        <MarkDown className="our-work-card-description">
                          {shortDescription?.data?.shortDescription}
                        </MarkDown>
                      </div>
                    </div>
                  </CTALink>
                )}
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}

export { OurWorkSlider };
