/* eslint-disable react/function-component-definition */
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { SLIDE_IN_ANIMATION_PROP } from 'types';

gsap.registerPlugin(ScrollTrigger);

export const SlideInAnimation = ({
  className,
  direction = 'left',
  showAnimation,
  children,
}: SLIDE_IN_ANIMATION_PROP) => {
  const cardsRef = useRef() as React.MutableRefObject<HTMLSpanElement>;
  useEffect(() => {
    if (showAnimation === true) {
      const CardAnimationPosition = window.innerWidth < 768 ? 200 : 100;
      let xvalue;
      if (direction === 'right') {
        xvalue = 200;
      } else {
        xvalue = -200;
      }
      gsap.fromTo(
        cardsRef.current,
        { x: xvalue, opacity: 0.5 },
        {
          duration: 1.5,
          x: 0,
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: cardsRef.current,
            start: `top center+=${CardAnimationPosition}`,
            toggleActions: 'play none none reverse',
          },
        }
      );
    }
  }, [showAnimation]);
  return (
    <span ref={cardsRef} className={className ?? className}>
      {children}
    </span>
  );
};
