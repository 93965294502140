/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { SectionHeading, MarkDown } from 'components';
import { truncate } from 'utils';
import Analysis from 'assets/svg/Analysis.svg';
import Development from 'assets/svg/development.svg';
import Design from 'assets/svg/Design.svg';
import Planning from 'assets/svg/Plan.svg';
import Test from 'assets/svg/Test.svg';
import Deploy from 'assets/svg/Deploy.svg';
import { OUR_PROCESS_SECTION_PROP, PROCESS_LIST_ITEM } from 'types';

export function OurProcessComponent({ data }: OUR_PROCESS_SECTION_PROP) {
  const [activeTab, setActiveTab] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };
  const characterLimit = 300;
  const {
    ProcessList = [],
    title = '',
    description = {
      data: {
        description: '',
      },
    },
  } = data;

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);
  return (
    <div className="ourProcess">
      <div className="ourProcess-content">
        <SectionHeading
          heading={title}
          tagline={description?.data?.description}
          align="left"
        />
        <div className="ourProcess-slider-container">
          {!isMobile &&
            ProcessList?.length > 0 &&
            ProcessList.map((tab: PROCESS_LIST_ITEM, index: number) => (
              <div
                className="ourProcess-slider"
                key={tab?.title}
                style={{ display: activeTab === index ? 'flex' : 'none' }}
              >
                <div className="ourProcess-slider-wrapper">
                  {tab?.title && (
                    <h3 className="ourProcess-slider-title">{tab.title}</h3>
                  )}
                  {tab?.description?.data && (
                    <MarkDown className="ourProcess-slider-description">
                      {truncate(tab.description.data, characterLimit)}
                    </MarkDown>
                  )}
                </div>
              </div>
            ))}
          <ul className="ourProcess-menu">
            {data?.ProcessList?.length > 0 &&
              data.ProcessList.map((tab: PROCESS_LIST_ITEM, index: number) => {
                const {
                  title = '',
                  description = { data: '' },
                  icon = '',
                } = tab;
                let serviceIcon;
                switch (icon) {
                  case 'analysis':
                    serviceIcon = <Analysis />;
                    break;
                  case 'planning':
                    serviceIcon = <Planning />;
                    break;
                  case 'design':
                    serviceIcon = <Design />;
                    break;
                  case 'development':
                    serviceIcon = <Development />;
                    break;
                  case 'testing':
                    serviceIcon = <Test />;
                    break;
                  case 'deployment':
                    serviceIcon = <Deploy />;
                    break;
                  default:
                    serviceIcon = <Analysis />;
                    break;
                }
                return (
                  <li key={title}>
                    {title && (
                      <button
                        type="button"
                        className={activeTab === index ? 'active' : ''}
                        onClick={() => handleTabClick(index)}
                      >
                        {serviceIcon} {title}
                      </button>
                    )}

                    {isMobile && (
                      <div
                        className="ourProcess-slider"
                        style={{
                          display: activeTab === index ? 'flex' : 'none',
                        }}
                      >
                        <div className="ourProcess-slider-wrapper">
                          {description?.data && (
                            <MarkDown className="ourProcess-slider-description">
                              {truncate(description.data, characterLimit)}
                            </MarkDown>
                          )}
                        </div>
                      </div>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}
