import React from 'react';
import Location from 'assets/svg/location-icon.svg';
import Experience from 'assets/svg/experience-icon.svg';
import Salary from 'assets/svg/salary-icon.svg';
import { truncate } from 'utils';
import { POSITION_CARD_PROP } from 'types';

export function PositionCard({
  data,
  handlePositionSelection,
}: POSITION_CARD_PROP) {
  const cardSummary = data?.promo?.data || '';
  const trimmedDescription =
    cardSummary?.length > 0 ? truncate(cardSummary, 165) : '';
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="position" onClick={() => handlePositionSelection(data)}>
      <div>
        {data?.title && <h4 className="position-title">{data.title}</h4>}
        {trimmedDescription && (
          <p className="position-desc">{trimmedDescription}</p>
        )}
      </div>
      <div className="position-feature">
        {data?.experience && (
          <div className="position-feature-item">
            <div className="position-feature-item-icon">
              <Salary />
            </div>
            <div className="position-feature-item-text">
              <p className="position-feature-item-text-value">
                {data.experience}
              </p>
              <p className="position-feature-item-text-label">Experience</p>
            </div>
          </div>
        )}
        {data?.salary && (
          <div className="position-feature-item">
            <div className="position-feature-item-icon">
              <Experience />
            </div>
            <div className="position-feature-item-text">
              <p className="position-feature-item-text-value">{data.salary}</p>
              <p className="position-feature-item-text-label">Annual Salary</p>
            </div>
          </div>
        )}
        {data?.location && (
          <div className="position-feature-item">
            <div className="position-feature-item-icon">
              <Location />
            </div>
            <div className="position-feature-item-text">
              <p className="position-feature-item-text-value">
                {data.location}
              </p>
              <p className="position-feature-item-text-label">Location</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
