import React from 'react';
import Logo from 'assets/svg/ozzitech-logo.svg';
import LinkedIn from 'assets/svg/linkedin.svg';
import Email from 'assets/svg/email.svg';
import FaceBook from 'assets/svg/facebook.svg';
import { FooterQuery } from 'Queries/Footer';
import { CTALink } from 'components';
import { FOOTER_NAV, FOOTER_DATA } from 'types';

export function Footer() {
  const FooterMenu: FOOTER_DATA = FooterQuery();
  const {
    footerNavigation,
    linkedInLink = '',
    facebookLink = '',
    mailLink = '',
    copyrightMessage = '',
  } = FooterMenu.strapiGlobal.footerComponent;

  return (
    <footer className="footer">
      <div className="container">
        <span className="blue" />
        <div className="footer-wrapper">
          <div className="footer-wrapper-content">
            <div className="footer-logo">
              <CTALink ctaLink="/">
                <Logo />
              </CTALink>
            </div>
            <ul className="footer-menu">
              {footerNavigation?.length > 0 &&
                footerNavigation.map((nav: FOOTER_NAV) => {
                  if (nav.title && nav.link && nav.active) {
                    return (
                      <li
                        className={`${nav.highlight ? 'highlight' : ''}`}
                        key={`footer-${nav.link}`}
                      >
                        <CTALink ctaLink={nav.link}>{nav.title}</CTALink>
                      </li>
                    );
                  }
                  return null;
                })}
            </ul>
            <div className="social_media">
              {linkedInLink && (
                <CTALink ctaLink={linkedInLink}>
                  <LinkedIn />
                </CTALink>
              )}
              {mailLink && (
                <a href={`mailto:${mailLink}`} rel="noreferrer">
                  <Email />
                </a>
              )}
              {facebookLink && (
                <CTALink ctaLink={facebookLink}>
                  <FaceBook />
                </CTALink>
              )}
            </div>
          </div>
          {copyrightMessage && (
            <div className="copyright">{copyrightMessage}</div>
          )}
        </div>
      </div>
    </footer>
  );
}
