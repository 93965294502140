import React from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MarkDown, SectionHeading } from 'components';
import { ABOUT_US_INFO_SECTION } from 'types';

function AboutUsContent({ aboutUs }: ABOUT_US_INFO_SECTION) {
  const {
    title = '',
    description = { data: { description: '' } },
    image = '',
    descriptionColumn = '',
  } = aboutUs;

  const Image =
    image && image?.localFile
      ? getImage(image?.localFile?.childImageSharp?.gatsbyImageData)
      : null;
  // TODO get heading from CMS
  return (
    <div className="about-us-content">
      <SectionHeading tagline={title} align="left" />
      {description?.data?.description && (
        <div className="about-us-content-wrapper">
          <MarkDown className={`about-us-content-summary ${descriptionColumn}`}>
            {description.data.description}
          </MarkDown>
        </div>
      )}
      {Image && (
        <div className="about-us-content-image">
          <GatsbyImage image={Image} alt={image?.alternativeText || title} />
        </div>
      )}
    </div>
  );
}

export { AboutUsContent };
