/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Logo from 'assets/svg/ozzitech.svg';
import { Menu, CTALink } from 'components';
import { MenuQuery } from 'Queries/Menu';
import { MENU } from 'types';

const Header = ({ path }: { path: string }) => {
  const {
    strapiGlobal: {
      navigation: { navItem },
    },
  }: MENU = MenuQuery();

  const [scroll, setScroll] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuDisplay = isMenuOpen ? 'active' : '';

  const MenuHandler = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 30);
    });
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const LogoTag = path === '/' ? 'h1' : 'div';

  return (
    <header
      className={classNames('header', {
        scrolled: scroll,
      })}
    >
      <div className="container">
        <div className="header_container">
          <LogoTag className="logo">
            <CTALink ctaLink="/" callBack={closeMenu}>
              <Logo />
              <span className="sr-only">Ozzitech</span>
            </CTALink>
          </LogoTag>
          <div className="menu-bar">
            <button
              type="button"
              className={`${menuDisplay}`}
              onClick={MenuHandler}
            >
              <span />
              <span />
              <span />
            </button>
          </div>

          <div
            className={
              isMenuOpen ? 'active menu-wrapper' : 'inactive menu-wrapper'
            }
          >
            <Menu mainNav={navItem} setIsMenuOpen={setIsMenuOpen} />
          </div>
        </div>
      </div>
    </header>
  );
}
export { Header };
