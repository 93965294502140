import React from 'react';
import { Link } from 'gatsby';
import { MarkDown } from 'components';

function ContentWithCTA({ data, modalTrigger }: any) {
  return (
    <div className="content-cta-banner">
      <h2 className="content-cta-banner-title">{data.title}</h2>
      <MarkDown className="content-cta-banner-description">
        {data?.description?.data?.description}
      </MarkDown>
      {data?.isLink ? (
        <Link
          to={data?.ctaLink}
          className="content-cta-banner-link btn-wrapper"
        >
          <span className="btn-wrapper-inner">{data.ctaText}</span>
        </Link>
      ) : (
        <button
          className="content-cta-banner-button btn-wrapper"
          type="button"
          onClick={modalTrigger}
        >
          <span className="btn-wrapper-inner">{data.ctaText}</span>
          <span className="btn-wrapper-icon" />
        </button>
      )}
    </div>
  );
}

export { ContentWithCTA };
