export const truncate = (str: string, max: number) => {
  const suffix = '...';
  return str && str.length < max
    ? str
    : str &&
        `${str.substr(
          0,
          str.substr(0, max - suffix.length).lastIndexOf(' ')
        )}${suffix}`;
};
export const comparePath = (urlA: string, urlB: string) => {
  if (urlA === urlB) {
    return true;
  }
  return urlB !== '/' && urlA.startsWith(urlB);
};
