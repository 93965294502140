import React, { useEffect, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MarkDown } from 'components';
import Logo from 'assets/svg/sectionlogo.svg';
import Line from 'assets/svg/line.svg';
import { WORK_BRIEF_PROP } from 'types';

export function WorkBrief({ data }: WORK_BRIEF_PROP) {
  const { video } = data;
  const brieftDataOne = data?.productDetails[0];
  const brieftDataTwo = data?.productDetails[1];
  const brieftDataThree = data?.productDetails[2];
  const brieftDataFour = data?.productDetails[3];
  const ImageOne =
    brieftDataOne?.image && brieftDataOne?.image?.localFile
      ? getImage(
          brieftDataOne?.image?.localFile?.childImageSharp?.gatsbyImageData
        )
      : null;
  const ImageTwo =
    brieftDataTwo?.image && brieftDataTwo?.image?.localFile
      ? getImage(
          brieftDataTwo?.image?.localFile?.childImageSharp?.gatsbyImageData
        )
      : null;
  const ImageThree =
    brieftDataThree?.image && brieftDataThree?.image?.localFile
      ? getImage(
          brieftDataThree?.image?.localFile?.childImageSharp?.gatsbyImageData
        )
      : null;
  const ImageFour =
    brieftDataFour?.image && brieftDataFour?.image?.localFile
      ? getImage(
          brieftDataFour?.image?.localFile?.childImageSharp?.gatsbyImageData
        )
      : null;

  const [showElement, setShowElement] = useState(true);
  const [showItem, setShowItem] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200) {
        setShowElement(true);
        setShowItem(false);
      } else {
        setShowElement(false);
        setShowItem(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="work-container">
      <div className="work-challenge-solution-wrapper">
        <div key={data?.title || ''} className="work-challenge-solution-item">
          {data?.title && (
            <>
              <Logo />
              <h3 className="work-challenge-solution-item-title">
                {data.title}
              </h3>
            </>
          )}
          {data?.summary?.data?.summary && (
            <div
              className={`work-challenge-solution-item-description ${
                data?.summaryColumn || ' '
              }`}
            >
              <MarkDown>{data.summary.data.summary}</MarkDown>
            </div>
          )}

          <div className="work-brief-main mobile">
            <div className="work-brief-video">
              {video?.localFile?.publicURL && (
                <video
                  preload="auto"
                  autoPlay
                  loop
                  playsInline
                  poster={video.localFile.publicURL}
                  muted
                  controls={false}
                  className="video"
                >
                  <source src={video.localFile.publicURL} type="video/mp4" />
                </video>
              )}
            </div>
          </div>
          <div className="work-brief">
            <div className="work-brief-item">
              <div className="windowframe-dots">
                <span />
                <span />
                <span />
              </div>
              {ImageOne && (
                <div className="work-brief-item-image">
                  <GatsbyImage
                    image={ImageOne}
                    alt={brieftDataOne?.image?.alternativeText || data?.title}
                  />
                </div>
              )}
              {brieftDataOne?.content?.data?.content && (
                <MarkDown className="work-brief-item-content">
                  {brieftDataOne.content.data.content}
                </MarkDown>
              )}
            </div>
            {showElement && (
              <div className="work-brief-item">
                <span className="line left">
                  <Line />
                </span>
                <div className="work-brief-main">
                  <div className="work-brief-video">
                    {video?.localFile?.publicURL && (
                      <video
                        preload="auto"
                        autoPlay
                        loop
                        playsInline
                        poster={video.localFile.publicURL}
                        muted
                        controls={false}
                        className="video"
                      >
                        <source
                          src={video.localFile.publicURL}
                          type="video/mp4"
                        />
                      </video>
                    )}
                  </div>
                </div>
                <span className="line right">
                  <Line />
                </span>
              </div>
            )}
            <div className="work-brief-item">
              <div className="windowframe-dots">
                <span />
                <span />
                <span />
              </div>
              {ImageTwo && (
                <div className="work-brief-item-image">
                  <GatsbyImage
                    image={ImageTwo}
                    alt={brieftDataTwo.image?.alternativeText || data?.title}
                  />
                </div>
              )}
              {brieftDataTwo.content?.data?.content && (
                <MarkDown className="work-brief-item-content">
                  {brieftDataTwo.content.data.content}
                </MarkDown>
              )}
            </div>
            {showItem && (
              <>
                <div className="work-brief-item">
                  <div className="windowframe-dots">
                    <span />
                    <span />
                    <span />
                  </div>
                  {ImageThree && (
                    <div className="work-brief-item-image">
                      <GatsbyImage
                        image={ImageThree}
                        alt={
                          brieftDataThree.image?.alternativeText || data?.title
                        }
                      />
                    </div>
                  )}
                  {brieftDataThree?.content?.data?.content && (
                    <MarkDown className="work-brief-item-content">
                      {brieftDataThree.content.data.content}
                    </MarkDown>
                  )}
                </div>
                <div className="work-brief-item">
                  <div className="windowframe-dots">
                    <span />
                    <span />
                    <span />
                  </div>
                  {ImageFour && (
                    <div className="work-brief-item-image">
                      <GatsbyImage
                        image={ImageFour}
                        alt={
                          brieftDataFour?.image?.alternativeText || data?.title
                        }
                      />
                    </div>
                  )}
                  {brieftDataFour?.content?.data?.content && (
                    <MarkDown className="work-brief-item-content">
                      {brieftDataFour.content.data.content}
                    </MarkDown>
                  )}
                </div>
              </>
            )}
          </div>
          {showElement && (
            <div className="work-brief-box">
              <div className="work-brief-item">
                <div className="windowframe-dots">
                  <span />
                  <span />
                  <span />
                </div>
                {ImageThree && (
                  <div className="work-brief-item-image">
                    <GatsbyImage
                      image={ImageThree}
                      alt={
                        brieftDataThree?.image?.alternativeText || data?.title
                      }
                    />
                  </div>
                )}
                {brieftDataThree?.content?.data?.content && (
                  <MarkDown className="work-brief-item-content">
                    {brieftDataThree.content.data.content}
                  </MarkDown>
                )}
              </div>
              <div className="work-brief-item">
                <div className="work-item-line">
                  <span className="line center" />
                  <span className="line vertical" />
                </div>
              </div>
              <div className="work-brief-item">
                <div className="windowframe-dots">
                  <span />
                  <span />
                  <span />
                </div>
                {ImageFour && (
                  <div className="work-brief-item-image">
                    <GatsbyImage
                      image={ImageFour}
                      alt={
                        brieftDataFour?.image?.alternativeText || data?.title
                      }
                    />
                  </div>
                )}
                {brieftDataFour?.content?.data?.content && (
                  <MarkDown className="work-brief-item-content">
                    {brieftDataFour.content.data.content}
                  </MarkDown>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
