import React from 'react';
import Logo from 'assets/svg/sectionlogo.svg';
import { PositionCard } from 'components';
import { TitleAnimation } from 'components/Animation';
import { JOIN_OUR_TEAM_SECTION, POSITION } from 'types';

export function CareerJoinOurTeam({
  data,
  handlePositionSelection,
  durationTime = 1,
  delaySec = 0,
  active = true,
}: JOIN_OUR_TEAM_SECTION) {
  if (!active) {
    return null;
  }
  return (
    <div className="join-our-team">
      <div className="join-our-team-detail">
        {data?.tagline && (
          <div className="join-our-team-section-title">
            <Logo className="content-section-logo" />
            <p>{data.tagline}</p>
          </div>
        )}
        {data?.title && (
          <TitleAnimation durationTime={durationTime} delaySec={delaySec}>
            <h2 className="join-our-team-title">{data.title}</h2>
          </TitleAnimation>
        )}
        {data?.summary?.data?.summary && (
          <p className="join-our-team-summary">{data.summary.data.summary}</p>
        )}
      </div>
      <div className="position-list">
        {data?.positions?.length > 0 &&
          data.positions.map((position: POSITION) => {
            return (
              <PositionCard
                key={position.id}
                data={position}
                handlePositionSelection={handlePositionSelection}
              />
            );
          })}
      </div>
    </div>
  );
}
