/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import axios from 'axios';
import { useStaticQuery, graphql } from 'gatsby';
import { FileInput } from 'components';
import DownArrow from 'assets/svg/down-arrow.svg';
import Loader from 'assets/svg/loader.svg';
import {
  CAREER_FORM,
  CAREER_FORM_DATA,
  POSITION,
  CAREER_FORM_HANDLER_INPUT,
} from 'types';
import data from './form.json';

function DropdownIndicator(props: DropdownIndicatorProps) {
  return (
    <components.DropdownIndicator {...props}>
      <DownArrow className="down-arrow-svg" />
    </components.DropdownIndicator>
  );
}

export function CareerForm({
  positionList,
  selectedPosition,
  isInputFocused,
  formSuccessMessage,
  formErrorMessage,
}: CAREER_FORM) {
  const [loading, isloading] = React.useState(false);
  const [formValidation, changeFormValidation] = React.useState('');

  const { details } = data;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    setFocus,
    setValue,
  } = useForm();

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const formQueryData: CAREER_FORM_DATA = careerFormQuery();

  const URL: string = formQueryData.site.siteMetadata.careerFormUrl;

  const formSubmission = (formdata: CAREER_FORM_HANDLER_INPUT) => {
    if (isValid) {
      isloading(true);
      axios
        .post(URL, {
          formState: formdata,
        })
        .then(response => {
          if (response) {
            isloading(false);
            changeFormValidation(formSuccessMessage);
          }
        })
        .catch(error => {
          if (!error.response?.data?.body?.comment) {
            changeFormValidation(formErrorMessage);
          } else {
            changeFormValidation(
              error.response.data.body.comment.replace(/\*/g, '')
            );
          }
          isloading(false);
        });
      setTimeout(() => {
        changeFormValidation('');
      }, 15000);
    }
  };

  const onSubmit = (values: any) => {
    const formData: CAREER_FORM_HANDLER_INPUT | any = values;
    if (Object.hasOwn(formData, 'attachedFile') && formData.attachedFile[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(formData.attachedFile[0]);
      reader.onloadend = () => {
        formData.attachedFile = reader.result;
        formSubmission(formData);
      };
    } else {
      formSubmission(formData);
    }
  };

  const colourStyles = {
    option: (styles: any, { isFocused, isSelected }: any) => ({
      ...styles,
      color: isFocused || isSelected ? 'rgba(17, 24, 29, 1)' : '#EEEEEE',
      backgroundColor:
        isSelected || isFocused ? '#EEEEEE' : 'rgba(17, 24, 29, 1)',
      fontWeight: 600,
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: 'rgba(17, 24, 29, 1)',
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom: '2px solid rgba(207, 216, 220, 0.5)',
      fontSize: '1.1rem',
      padding: '1.5rem 0',
      outline: 'none !important',
      borderRadius: 0,
      boxShadow: 'none',
      '&:hover': {
        borderBottom: '2px solid rgba(207, 216, 220, 0.5)',
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#EEEEEE',
    }),
  };

  const selectOption = positionList.map((position: POSITION) => {
    return {
      value: position.title,
      label: position.title,
    };
  });

  useEffect(() => {
    if (selectedPosition?.title) {
      const filteredValue = selectOption.filter((opt: any) => {
        return opt.value === selectedPosition.title;
      });
      if (filteredValue.length > 0) {
        setValue('position', filteredValue[0]);
      }
    }
  }, [selectedPosition]);

  useEffect(() => {
    if (isInputFocused) {
      setFocus('name');
    }
  }, [isInputFocused]);

  return (
    <div className="career-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="career-form-wrapper"
        id="careerForm"
      >
        <div className="contact-form-field">
          <label className="contact-form-field-label" htmlFor="position">
            {details.position.label}
            {details.position.required === true ? (
              <span className="required">*</span>
            ) : (
              ''
            )}
          </label>
          <Controller
            name={details.position.name}
            control={control}
            rules={{ required: 'Please select a position' }}
            render={({ field }) => (
              <Select
                {...field}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                classNamePrefix="react-select"
                placeholder={details.position.input}
                options={selectOption}
                styles={colourStyles}
              />
            )}
          />
          <div className="error-message">
            <ErrorMessage
              errors={errors}
              name={details.position.name}
              render={({ message }) => <p>{message}</p>}
            />
          </div>
        </div>
        <div className="form-field">
          <label className="form-field-label" htmlFor="name">
            {details.name.label}
            {details.name.required === true ? (
              <span className="required">*</span>
            ) : (
              ''
            )}
          </label>
          {details.name.required === true ? (
            <>
              <input
                type="text"
                placeholder={details.name.input}
                {...register(`${details.name.name}`, {
                  required: {
                    value: true,
                    message: 'Full Name is required',
                  },
                  pattern: {
                    value: /^[a-zA-Z ]+$/g,
                    message: 'Invalid Name. Avoid Special characters',
                  },
                })}
                className="form-field-input"
              />

              {details.name.name && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={details.name.name}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="form-field-input"
              placeholder={details.name.input}
              {...register(`${details.name.name}`)}
            />
          )}
        </div>
        <div className="form-field">
          <label className="form-field-label" htmlFor="email">
            {details.email.label}
            {details.email.required === true ? (
              <span className="required">*</span>
            ) : (
              ''
            )}
          </label>
          {details.email.required === true ? (
            <>
              <input
                type="text"
                placeholder={details.email.input}
                {...register(`${details.email.name}`, {
                  required: {
                    value: true,
                    message: 'Email is required',
                  },
                  pattern: {
                    value:
                      /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/g,
                    message: 'Invalid Email',
                  },
                })}
                className="form-field-input"
              />
              {details.email.name && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={details.email.name}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="form-field-input"
              placeholder={details.email.input}
              {...register(`${details.email.name}`)}
            />
          )}
        </div>
        <div className="form-field">
          <label className="form-field-label" htmlFor="phoneNo">
            {details.phone.label}
            {details.phone.required === true ? (
              <span className="required">*</span>
            ) : (
              ''
            )}
          </label>
          {details.phone.required === true ? (
            <>
              <input
                type="text"
                placeholder={details.phone.input}
                {...register(`${details.phone.name}`, {
                  required: {
                    value: true,
                    message: 'Number is required',
                  },
                  pattern: {
                    value: /^[0-9]{5,}$/g,
                    message: 'Please check the number',
                  },
                })}
                className="form-field-input"
              />
              {details.phone.name && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={details.phone.name}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="form-field-input"
              placeholder={details.phone.input}
              {...register(`${details.phone.name}`)}
            />
          )}
        </div>
        <div className="contact-form-field">
          <label className="contact-form-field-label" htmlFor="about">
            {details.about.label}
            {details.about.required === true ? (
              <span className="required">*</span>
            ) : (
              ''
            )}
          </label>
          {details.about.required === true ? (
            <>
              <textarea
                cols={30}
                rows={7}
                className="contact-form-field-textarea"
                placeholder={details.about.input}
                {...register(`${details.about.name}`, {
                  required: {
                    value: true,
                    message: 'project description is required',
                  },
                })}
              />
              {details.about.name && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={details.about.name}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <textarea
              cols={30}
              rows={7}
              className="contact-form-field-textarea"
              placeholder={details.about.input}
              {...register(`${details.about.name}`)}
            />
          )}
        </div>
        <div className="form-field form-field-file">
          <label className="form-field-label" htmlFor="name">
            {details.file.label} ( <span>pdf file</span> )
          </label>
          <FileInput
            register={register}
            buttonText={details.file.input}
            Required={false}
            requiredMessage="Resume is Required"
          />
          {details.file.name && (
            <div className="error-message">
              <ErrorMessage
                errors={errors}
                name={details.file.name}
                render={({ message }) => <p>{message}</p>}
              />
            </div>
          )}
        </div>
        <div className="form-submit">
          <button type="submit" className="btn-primary career-btn">
            {loading ? (
              <span className="btn-wrapper-loading">
                <Loader />
              </span>
            ) : (
              <span className="btn-wrapper-inner">{details.submit.text}</span>
            )}
          </button>
        </div>
      </form>
      {formValidation && <p className="form-message">{formValidation}</p>}
    </div>
  );
}

const careerFormQuery = () => {
  return useStaticQuery(graphql`
    query careerFormQuery {
      site {
        siteMetadata {
          careerFormUrl
        }
      }
    }
  `);
};
