/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import { useStaticQuery, graphql } from 'gatsby';
import Loader from 'assets/svg/loader.svg';
import { CONTACT_FORM } from 'types';
import data from './form.json';

export function ContactForm({
  formSuccessMessage,
  formErrorMessage,
}: CONTACT_FORM) {
  const { details, submit } = data;
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const [loading, isloading] = React.useState(false);
  const [formDisabled, setFormDiabled] = React.useState(false);
  const [formValidation, changeFormValidation] = React.useState('');

  const formQueryData: any = contactFormQuery();

  const URL: string = formQueryData.site.siteMetadata.contactFormUrl;

  const formSubmission = (formdata: any) => {
    if (isValid) {
      isloading(true);
      setFormDiabled(true);
      axios
        .post(URL, {
          formState: formdata,
        })
        .then(response => {
          if (response) {
            isloading(false);
            changeFormValidation(formSuccessMessage);
            setFormDiabled(false);
          }
        })
        .catch(error => {
          if (!error.response?.data?.body?.comment) {
            changeFormValidation(formErrorMessage);
          } else {
            changeFormValidation(
              error.response.data.body.comment.replace(/\*/g, '')
            );
          }
          isloading(false);
          setFormDiabled(false);
        });
      setTimeout(() => {
        changeFormValidation('');
      }, 15000);
    }
  };

  const onSubmit = (values: any) => {
    formSubmission(values);
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="contact-form-wrapper"
      id="contactForm"
    >
      {formDisabled && <div className="contact-form-loader" />}
      <div className="contact-form-details">
        <div className="contact-form-section">
          <div className="contact-form-field">
            <label className="contact-form-field-label" htmlFor="name">
              {details.name.label}
              {details.name.required === true ? (
                <span className="required">*</span>
              ) : (
                ''
              )}
            </label>
            {details.name.required === true ? (
              <>
                <input
                  type="text"
                  placeholder={details.name.input}
                  {...register(`${details.name.name}`, {
                    required: {
                      value: true,
                      message: 'Full Name is required',
                    },
                    pattern: {
                      value: /^[a-zA-Z ]+$/g,
                      message: 'Invalid Name. Avoid Special characters',
                    },
                  })}
                  className="contact-form-field-input"
                />

                {details.name.name && (
                  <div className="error-message">
                    <ErrorMessage
                      errors={errors}
                      name={details.name.name}
                      render={({ message }) => <p>{message}</p>}
                    />
                  </div>
                )}
              </>
            ) : (
              <input
                type="text"
                className="contact-form-field-input"
                placeholder={details.name.input}
                {...register(`${details.name.name}`)}
              />
            )}
          </div>
          <div className="contact-form-field">
            <label className="contact-form-field-label" htmlFor="email">
              {details.email.label}
              {details.email.required === true ? (
                <span className="required">*</span>
              ) : (
                ''
              )}
            </label>
            {details.email.required === true ? (
              <>
                <input
                  type="text"
                  placeholder={details.email.input}
                  {...register(`${details.email.name}`, {
                    required: {
                      value: true,
                      message: 'Email is required',
                    },
                    pattern: {
                      value:
                        /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/g,
                      message: 'Invalid Email',
                    },
                  })}
                  className="contact-form-field-input"
                />
                {details.email.name && (
                  <div className="error-message">
                    <ErrorMessage
                      errors={errors}
                      name={details.email.name}
                      render={({ message }) => <p>{message}</p>}
                    />
                  </div>
                )}
              </>
            ) : (
              <input
                type="text"
                className="contact-form-field-input"
                placeholder={details.email.input}
                {...register(`${details.email.name}`)}
              />
            )}
          </div>
          <div className="contact-form-field">
            <label className="contact-form-field-label" htmlFor="phoneNo">
              {details.phone.label}
              {details.phone.required === true ? (
                <span className="required">*</span>
              ) : (
                ''
              )}
            </label>
            {details.phone.required === true ? (
              <>
                <input
                  type="text"
                  placeholder={details.phone.input}
                  {...register(`${details.phone.name}`, {
                    required: {
                      value: true,
                      message: 'Number is required',
                    },
                    pattern: {
                      value: /^[0-9]{5,}$/g,
                      message: 'Please check the number',
                    },
                  })}
                  className="contact-form-field-input"
                />
                {details.phone.name && (
                  <div className="error-message">
                    <ErrorMessage
                      errors={errors}
                      name={details.phone.name}
                      render={({ message }) => <p>{message}</p>}
                    />
                  </div>
                )}
              </>
            ) : (
              <input
                type="text"
                className="contact-form-field-input"
                placeholder={details.phone.input}
                {...register(`${details.phone.name}`)}
              />
            )}
          </div>
          <div className="contact-form-field">
            <label className="contact-form-field-label" htmlFor="about">
              {details.about.label}
              {details.about.required === true ? (
                <span className="required">*</span>
              ) : (
                ''
              )}
            </label>
            {details.about.required === true ? (
              <>
                <textarea
                  cols={30}
                  rows={7}
                  className="contact-form-field-textarea"
                  placeholder={details.about.input}
                  {...register(`${details.about.name}`, {
                    required: {
                      value: true,
                      message: 'Message is required',
                    },
                  })}
                />
                {details.about.name && (
                  <div className="error-message">
                    <ErrorMessage
                      errors={errors}
                      name={details.about.name}
                      render={({ message }) => <p>{message}</p>}
                    />
                  </div>
                )}
              </>
            ) : (
              <textarea
                cols={30}
                rows={7}
                className="contact-form-field-textarea"
                placeholder={details.about.input}
                {...register(`${details.about.name}`)}
              />
            )}
          </div>
          {formValidation && <p className="form-message">{formValidation}</p>}
          <div className="contact-form-submit">
            <button type="submit" className="btn-primary contact-us-btn">
              {loading ? (
                <span className="btn-wrapper-loading">
                  <Loader />
                </span>
              ) : (
                <span className="btn-wrapper-inner">{submit.buttonText}</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

const contactFormQuery = () => {
  return useStaticQuery(graphql`
    query contactFormQuery {
      site {
        siteMetadata {
          contactFormUrl
        }
      }
    }
  `);
};
