import React from 'react';
import { Header, Footer } from 'components';
import 'sass/style.scss';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';

// eslint-disable-next-line import/no-extraneous-dependencies

export interface LayoutProps {
  children: JSX.IntrinsicElements['div'] | any;
  path: string;
}

function Layout({ children, path }: LayoutProps) {
  return (
    <div className="main-wrapper">
      <Header path={path} />
      <main>{children}</main>
      <Footer />
    </div>
  );
}

export default Layout;
