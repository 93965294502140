/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

interface SeoProps {
  description?: string;
  lang?: string;
  title: string;
  image?: any;
}

function Seo({ description, lang = 'en', title, image }: SeoProps) {
  const { pathname } = useLocation();
  return (
    <StaticQuery
      query={seoDetailsQuery}
      render={data => {
        const {
          site: {
            siteMetadata: { defaultTitle, siteUrl, faceBookAppId },
          },
          strapiGlobal: {
            defaultSeo: {
              metaTitle: defaultMetaTitle,
              metaDescription: defaultMetaDescription,
              shareImage: shareImageURL,
            },
          },
        } = data;

        const seoTitle = title || defaultMetaTitle;
        const seoDescription =
          description || defaultMetaDescription?.data?.metaDescription;
        const metaUrl = `${siteUrl}${pathname}`;
        const metaImage =
          (image && `${siteUrl}${image?.localFile?.publicURL}`) ??
          (shareImageURL && `${siteUrl}${shareImageURL?.localFile?.publicURL}`);
        const canonical = pathname ? `${siteUrl}${pathname}` : null;
        const fbAppId = faceBookAppId || '';
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={seoTitle}
            titleTemplate={`%s |  ${defaultTitle}`}
            link={
              canonical
                ? [
                    {
                      rel: 'canonical',
                      href: canonical,
                    },
                  ]
                : []
            }
            meta={[
              {
                name: 'description',
                content: seoDescription,
              },
              {
                name: 'image',
                content: metaImage,
              },
              {
                property: 'og:title',
                content: seoTitle,
              },
              {
                property: 'og:description',
                content: seoDescription,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                name: 'og:image:width',
                content: 200,
              },
              {
                name: 'og:image:height',
                content: 150,
              },
              {
                property: 'og:url',
                content: metaUrl,
              },
              {
                name: 'twitter:card',
                content: 'summary',
              },
              {
                name: 'twitter:title',
                content: seoTitle,
              },
              {
                name: 'twitter:description',
                content: seoDescription,
              },
              {
                name: 'twitter:image',
                content: metaImage,
              },
              {
                name: 'fb:app_id',
                content: fbAppId,
              },
            ].concat(
              metaImage
                ? [
                    {
                      property: `og:image`,
                      content: metaImage,
                    },
                    {
                      property: `og:image:alt`,
                      content: seoTitle,
                    },
                  ]
                : []
            )}
          />
        );
      }}
    />
  );
}

export { Seo };

const seoDetailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        faceBookAppId
      }
    }
    strapiGlobal {
      defaultSeo {
        metaTitle
        metaDescription {
          data {
            metaDescription
          }
        }
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
