/* eslint-disable react/function-component-definition */
import React from 'react';
import Logo from 'assets/svg/sectionlogo.svg';
import { MarkDown } from 'components';
import { TitleAnimation } from 'components/Animation';

interface MarkDownProps {
  heading?: string;
  tagline?: string;
  align?: 'left' | 'center';
  animatedTitle?: boolean;
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
}
type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

const Tagline = ({ tagline }: { tagline: string }) => (
  <MarkDown context="heading" className="section-tagline">
    {tagline}
  </MarkDown>
);

const SectionHeading = ({
  heading,
  tagline,
  align,
  animatedTitle,
  headingLevel,
}: MarkDownProps) => {
  const durationTime = 1;
  const delaySec = 0;
  const HTag = headingLevel ? (`h${headingLevel}` as HeadingTag) : 'h2';

  return (
    <div className={`section-heading ${align}`}>
      <Logo className="section-logo" />
      {heading && <HTag className="section-title">{heading}</HTag>}
      {tagline &&
        (animatedTitle ? (
          <TitleAnimation durationTime={durationTime} delaySec={delaySec}>
            <Tagline tagline={tagline} />
          </TitleAnimation>
        ) : (
          <Tagline tagline={tagline} />
        ))}
    </div>
  );
};

SectionHeading.defaultProps = {
  heading: null,
  tagline: null,
  align: 'center',
  animatedTitle: true,
  headingLevel: 2,
};

export { SectionHeading };
