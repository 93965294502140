import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MarkDown } from 'components';
import Logo from 'assets/svg/sectionlogo.svg';
import { TitleAnimation } from 'components/Animation';
import { ABOUT_PROJECT_PROP } from 'types';

function AboutProject({
  data,
  durationTime = 0,
  delaySec = 0,
}: ABOUT_PROJECT_PROP) {
  const {
    title = '',
    description = {
      data: {
        description: '',
      },
    },
    image = null,
    video = null,
  } = data;
  const ImageItem =
    image && image?.localFile
      ? getImage(image?.localFile?.childImageSharp?.gatsbyImageData)
      : null;
  return (
    <div className="aboutproject">
      <span className="gradient-orange" />
      <div className="aboutproject-wrapper">
        <div className="aboutproject-img">
          <div className="aboutproject-windowframe-dots">
            <span />
            <span />
            <span />
          </div>
          {video
            ? video?.localFile?.publicURL && (
                <video
                  preload="auto"
                  autoPlay
                  loop
                  playsInline
                  poster={image?.localFile.publicURL}
                  muted
                  controls={false}
                  className="video"
                >
                  <source src={video?.localFile.publicURL} type="video/mp4" />
                </video>
              )
            : ImageItem && (
                <GatsbyImage
                  image={ImageItem}
                  alt={image?.alternativeText || title}
                />
              )}
        </div>
        <div className="aboutproject-detail">
          {title && (
            <>
              <Logo className="content-section-logo" />
              <TitleAnimation
                durationTime={durationTime || 1}
                delaySec={delaySec}
              >
                <h2 className="aboutproject-detail-title">{title}</h2>
              </TitleAnimation>
            </>
          )}
          {description?.data?.description && (
            <MarkDown className="aboutproject-detail-desc">
              {description.data.description}
            </MarkDown>
          )}
        </div>
      </div>
    </div>
  );
}

export { AboutProject };
