import React from 'react';
import { CtaButton } from 'components/CtaButton';
import { SHARED_BANNER_PROPS } from 'types';

interface HOME_BANNER_PROPS_TYPE {
  banner: SHARED_BANNER_PROPS;
}

function HomeBanner({ banner }: HOME_BANNER_PROPS_TYPE) {
  return (
    <div className="home-banner-section banner-wrapper">
      <div className="home-banner">
        <div className="home-banner-content">
          <div className="home-banner-content-wrapper">
            {banner?.bannerTitle?.data?.bannerTitle && (
              <h3 className="home-banner-title">
                {banner.bannerTitle.data.bannerTitle}
              </h3>
            )}
            {banner?.ctaTitle && banner?.ctaLink && (
              <div className="banner-button">
                <CtaButton
                  ctaTitle={banner.ctaTitle}
                  ctaLink={banner.ctaLink}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { HomeBanner };
