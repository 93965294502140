/* eslint-disable react/function-component-definition */
import React, { FC } from 'react';
import Logo from 'assets/svg/sectionlogo.svg';
import Team from 'assets/svg/gradient-users.svg';
import Client from 'assets/svg/gradient-user.svg';
import Experience from 'assets/svg/gradient-briefcase.svg';
import Project from 'assets/svg/gradient-monitor.svg';
import { TitleAnimation } from 'components/Animation';
import { FACTS_AND_FIGURES_PROPS } from 'types';
import { SectionHeading } from 'components';

export const FactsAndFigures: FC<FACTS_AND_FIGURES_PROPS> = ({
  factsAndFigures,
  durationTime = 1,
  delaySec = 0,
}) => {
  const {
    title = '',
    tagline = { data: { tagline: '' } },
    summary = { data: { summary: '' } },
    teamMemberTitle = '',
    projectsTitle = '',
    clientsTitle = '',
    experienceTitle = '',
    teamMemberValue = '',
    projectsValue = '',
    clientsValue = '',
    experienceValue = '',
  } = factsAndFigures;
  return (
    <section className="facts-and-figures">
      <div className="facts-and-figures-content">
        <SectionHeading
          heading={title}
          tagline={tagline?.data?.tagline}
          align="left"
        />
        {summary?.data?.summary && (
          <p className="facts-and-figures-content-summary">
            {summary.data.summary}
          </p>
        )}
      </div>
      <div className="facts-and-figures-image">
        <div className="facts-and-figures-image-wrapper">
          {teamMemberValue && teamMemberTitle && (
            <div className="circle-svg circle-svg-1">
              <div className="info-circle team">
                <Team />
                <span />
                <p className="info-circle-value">{teamMemberValue}</p>
                <p className="info-circle-title">{teamMemberTitle}</p>
              </div>
            </div>
          )}
          {projectsValue && projectsTitle && (
            <div className="circle-svg circle-svg-2">
              <div className="info-circle project">
                <Project />
                <span />
                <p className="info-circle-value">{projectsValue}</p>
                <p className="info-circle-title">{projectsTitle}</p>
              </div>
            </div>
          )}
          {clientsValue && clientsTitle && (
            <div className="circle-svg circle-svg-3">
              <div className="info-circle client">
                <Client />
                <span />
                <p className="info-circle-value">{clientsValue}</p>
                <p className="info-circle-title">{clientsTitle}</p>
              </div>
            </div>
          )}
          {experienceValue && experienceTitle && (
            <div className="circle-svg circle-svg-4">
              <div className="info-circle experience">
                <Experience />
                <span />
                <p className="info-circle-value">{experienceValue}</p>
                <p className="info-circle-title">{experienceTitle}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
