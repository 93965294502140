import React from 'react';
import ReactModal from 'react-modal';
import { Seo } from 'components';
import { MODAL_CONTENT } from 'types';

export function ModalContent({
  isModalOpen,
  closeModal,
  item,
  children,
}: MODAL_CONTENT) {
  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
    >
      <Seo title={item?.title || ''} description={item?.summary?.data} />
      <div className="Modal-bg">
        <button type="button" className="close-btn" onClick={closeModal}>
          &times;
        </button>
        {children}
      </div>
    </ReactModal>
  );
}
