import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MarkDown } from 'components';

export function PageContent({ data }: any) {
  const ImageItem =
    data.image && data.image?.localFile
      ? getImage(data.image?.localFile?.childImageSharp?.gatsbyImageData)
      : null;
  return (
    <div className="page-content">
      <h6 className="page-content-title">{data?.title}</h6>
      <MarkDown className="page-content-description">
        {data?.description?.data?.description}
      </MarkDown>
      {ImageItem && (
        <GatsbyImage
          image={ImageItem}
          alt={data.image?.alternativeText || data.title}
        />
      )}
      <div className="page-content-block">
        {data.ImageAndDescription.map((item: any) => {
          const Image =
            item?.image && item.image?.localFile
              ? getImage(
                  item.image?.localFile?.childImageSharp?.gatsbyImageData
                )
              : null;
          return (
            <div className="page-content-block-item">
              <MarkDown className="page-content-description">
                {item?.content?.data?.content}
              </MarkDown>
              {Image && (
                <GatsbyImage
                  image={Image}
                  alt={item.image?.alternativeText || item.title}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
