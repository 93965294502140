import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import { Seo, GetInTouch } from 'components';

export interface PageLayoutProps {
  children: React.ReactNode;
  title: string;
  description?: string;
  image?: any;
  metaTitle?: string;
  showGetInTouchWidget?: boolean;
}
export function PageLayout({
  children,
  title,
  description,
  image,
  metaTitle,
  showGetInTouchWidget = true,
}: PageLayoutProps) {
  const { pathname } = useLocation();
  useEffect(() => {
    const scrollTimeOut = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);

    return () => clearTimeout(scrollTimeOut);
  }, [pathname]);
  return (
    <>
      <Seo title={metaTitle ?? title} description={description} image={image} />
      {children}
      {showGetInTouchWidget && <GetInTouch />}
    </>
  );
}
