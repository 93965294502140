import React from 'react';
import { Link } from 'gatsby';
import { CTA_LINK_PROPS } from 'types';

export function CTALink({
  ctaLink,
  children,
  className,
  callBack,
}: CTA_LINK_PROPS) {
  if (!ctaLink) {
    return null;
  }
  if (ctaLink.includes('://')) {
    return (
      <a
        href={ctaLink}
        target="_blank"
        rel="noreferrer"
        className={`${className || ''}`}
        onClick={callBack && callBack}
      >
        {children}
      </a>
    );
  }
  const localLink = ctaLink.startsWith('/') ? `${ctaLink}` : `/${ctaLink}`;
  return (
    <Link
      to={localLink}
      className={`${className || ''}`}
      onClick={callBack && callBack}
    >
      {children}
    </Link>
  );
}
