/* eslint-disable react/no-array-index-key */
import React from 'react';
import { MarkDown } from 'components';
import { TitleAnimation } from 'components/Animation';
import { CAREER_SUMMARY_SECTION, CAREER_SUMMARY } from 'types';

function CareerSummary({
  careerSummary,
  durationTime = 1,
  delaySec = 0,
}: CAREER_SUMMARY_SECTION) {
  return (
    <div className="career-summary">
      {careerSummary?.length > 0 &&
        careerSummary.map((item: CAREER_SUMMARY, index: number) => {
          return (
            <div
              className="career-summary-content"
              key={`title${item.title || index}`}
            >
              <div className="career-summary-title">
                {item?.title && (
                  <TitleAnimation
                    durationTime={durationTime}
                    delaySec={delaySec}
                  >
                    <h3>{item.title}</h3>
                  </TitleAnimation>
                )}
                {item?.summary?.data?.summary && (
                  <MarkDown>{item.summary.data.summary}</MarkDown>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export { CareerSummary };
