/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { gsap } from 'gsap';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import { BufferGeometry, BufferAttribute } from 'three';
import * as THREE from 'three';
import panalnetTexture from '../../assets/images/2k_earth_daymap.jpg';
// mars like
// import panalnetTexture from '../../assets/images/2k_makemake_fictional.jpg';
// grey
// import panalnetTexture from '../../assets/images/2k_eris_fictional.jpg';
// import panalnetTexture from '../../assets/images/2k_ceres_fictional.jpg';
import starTexture from '../../assets/images/star.png';

function ParticleMesh({ particlesCnt = 5000 }) {
  const particleRef = useRef<THREE.Points>(null!);
  const starImg = useLoader(TextureLoader, starTexture);
  const [mousePosition, setMousePosition] = useState({
    mouseX: 0,
    mouseY: 0,
  });

  const posArray = useMemo(() => {
    const array = new Float32Array(particlesCnt * 3);
    for (let i = 0; i < particlesCnt * 3; i++) {
      array[i] = (Math.random() - 0.5) * 150;
    }
    return array;
  }, [particlesCnt]);

  const particleGeometry = useMemo(() => {
    const geometry = new BufferGeometry();
    geometry.setAttribute('position', new BufferAttribute(posArray, 3));
    return geometry;
  }, [posArray]);

  useEffect(() => {
    if (particleRef?.current) {
      particleRef.current.rotation.x = mousePosition.mouseX * 0.0005;
      particleRef.current.rotation.y = -mousePosition.mouseY * 0.0005;
    }
  }, [mousePosition]);
  useFrame(() => {
    if (particleRef?.current) {
      particleRef.current.rotation.x += 0.0003;
      particleRef.current.rotation.y += 0.0003;
    }
  });

  useEffect(() => {
    function animateParticle(event: any) {
      setMousePosition({
        mouseX: event.clientX,
        mouseY: event.clientY,
      });
    }
    document.addEventListener('mousemove', animateParticle);
    return () => document.removeEventListener('mousemove', animateParticle);
  }, []);

  return (
    <points geometry={particleGeometry} ref={particleRef}>
      <pointsMaterial size={0.2} map={starImg} transparent />
    </points>
  );
}

function Scene({
  planetRef,
  scrollAnimation,
  planetInitialPosition,
  breakpoint,
}: any) {
  const colorMap = useLoader(TextureLoader, panalnetTexture);
  const [planetPosition, setPlannetPosition] = useState<any>([0, 0, 0]);
  
  useFrame(() => {
    if (planetRef?.current) {
      planetRef.current.rotation.y += 0.0004;
    }
  });

  const particlesCnt = 5000;

  useEffect(() => {
    if (planetRef?.current) {
      planetRef.current.rotation.y = 1.5;
    }
    setPlannetPosition(
      window.innerWidth > breakpoint
        ? planetInitialPosition.desktop
        : planetInitialPosition.mobile
    );
    const ctx = gsap.context(() => {
      const mediaQuery = gsap.matchMedia();
      // Mobile
      mediaQuery.add(
        `(max-width: ${breakpoint}px)`,
        () => {
          scrollAnimation.mobile.map((animate: any) => {
            animate.section.to(
              planetRef.current[animate.property],
              animate.animation
            );
          });
        },
        planetRef
      );

      // Desktop
      mediaQuery.add(`(min-width: ${breakpoint}px)`, () => {
        scrollAnimation.desktop.map((animate: any) => {
          animate.section.to(
            planetRef.current[animate.property],
            animate.animation
          );
        });
      });
    }, planetRef);
    return () => ctx.revert();
  }, []);

  return (
    <>
      <ambientLight intensity={0.1} />
      <directionalLight
        intensity={1.5}
        position={[-10, 2, 1]}
        shadow-mapSize-width={2048}
        shadow-mapSize-height={2048}
        castShadow
      />
      <mesh ref={planetRef} position={planetPosition}>
        <sphereGeometry args={[3, 72, 72]} />
        <meshPhongMaterial map={colorMap} />
      </mesh>
      <ParticleMesh particlesCnt={particlesCnt} />
    </>
  );
}

function HomeThreeScene({
  planetRef,
  scrollAnimation,
  planetInitialPosition = null,
  breakpoint,
}: any) {
  return (
    <Canvas
      style={{ height: '100vh', position: 'fixed' }}
      camera={{ fov: 20, near: 0.1, far: 1000, position: [0, 0, 50] }}
    >
      <Scene
        planetRef={planetRef}
        scrollAnimation={scrollAnimation}
        planetInitialPosition={planetInitialPosition}
        breakpoint={breakpoint}
      />
    </Canvas>
  );
}

export { HomeThreeScene };
