/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ShowPlus from 'assets/svg/showplus.svg';
import { TitleAnimation } from 'components/Animation';
import { CAREER_AIM_SECTION, CAREER_CHANCE, CAREER_KEY_VALUE } from 'types';

function CareerJoinUs({
  careerAim,
  durationTime = 1,
  delaySec = 0,
}: CAREER_AIM_SECTION) {
  const { title = '', careerChances } = careerAim;

  const Style = {
    height: 0,
  } as React.CSSProperties;

  useEffect(() => {
    setTimeout(() => {
      const AccordionItem = document.getElementsByClassName(
        'career-join-us-accordion'
      ) as any;
      const AccordionTitle = document.getElementsByClassName(
        'career-join-us-accordion-title'
      ) as any;

      const removeActiveClass = () => {
        for (let i = 0; i < AccordionItem.length; i++) {
          AccordionItem[i].classList.remove('active');
          AccordionItem[i].children[1].style.height = '0px';
        }
      };

      for (let i = 0; i < AccordionTitle.length; i++) {
        AccordionTitle[i].addEventListener('click', () => {
          const isAccordionActive =
            !AccordionItem[i].classList.contains('active');
          removeActiveClass();
          if (isAccordionActive) {
            AccordionItem[i].classList.toggle('active');
            const height =
              AccordionItem[i].children[1].children[0].getBoundingClientRect()
                .height + 30;
            AccordionItem[i].children[1].style.height = `${height}px`;
          }
        });
      }
    }, 10);
  }, []);
  return (
    <div className="career-join-us-section">
      {title && (
        <TitleAnimation durationTime={durationTime} delaySec={delaySec}>
          <div className="section-title">{title}</div>
        </TitleAnimation>
      )}

      {careerChances?.length > 0 &&
        careerChances.map((data: CAREER_CHANCE, index: number) => {
          const { id = '', title = '', image = null, career_key_values } = data;
          const careerKeyValues = career_key_values;
          const careerChanceImage =
            image && image?.localFile
              ? getImage(image?.localFile?.childImageSharp?.gatsbyImageData)
              : null;
          return (
            <div className="career-join-us" key={id}>
              <div className="career-join-us-content">
                <p>0{index + 1}</p>
                {title && <h2 className="text-highlight">{title}</h2>}
              </div>
              <div className="career-join-us-image">
                {careerChanceImage && (
                  <GatsbyImage
                    image={careerChanceImage}
                    alt={image?.alternativeText || title}
                  />
                )}
                <div className="career-join-us-wrapper">
                  {careerKeyValues.map((item: CAREER_KEY_VALUE) => {
                    const {
                      id = '',
                      title = '',
                      description = { data: '' },
                    } = item;
                    return (
                      <div className="career-join-us-accordion" key={id}>
                        {title && (
                          <button
                            type="button"
                            className="career-join-us-accordion-title"
                          >
                            <div className="toggle_icon">
                              <ShowPlus />
                            </div>
                            <h3>{title}</h3>
                          </button>
                        )}
                        <div
                          className="career-join-us-content-description"
                          style={Style}
                        >
                          {description?.data && (
                            <div className="career-join-us-content-answer">
                              {description.data}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export { CareerJoinUs };
