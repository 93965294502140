import React from 'react';

import Logo from 'assets/svg/sectionlogo.svg';
import { truncate } from 'utils';
import { TitleAnimation } from 'components/Animation';
import { TechnologyIcons, MarkDown } from 'components';
import { OPTIMAL_STACK_SECTION_PROP } from 'types';

function OptimalStack({
  optimalStack,
  durationTime = 0,
  delaySec = 0,
}: OPTIMAL_STACK_SECTION_PROP) {
  const {
    title = '',
    tagline = '',
    description = {
      data: {
        description: '',
      },
    },
    technologies,
    technologyTools,
    toolsTitle = '',
    stackTitle = '',
    active,
  } = optimalStack;
  if (!active) {
    return null;
  }
  const characterLimit = 80;

  return (
    <div className="optimal-stack-container">
      {title && (
        <>
          <Logo className="optimal-stack-logo" />
          <p className="optimal-stack-title">{title}</p>
        </>
      )}

      <div className="optimal-stack-desc-wrapper">
        {tagline && (
          <TitleAnimation
            durationTime={durationTime || 1}
            delaySec={delaySec}
            className="optimal-stack-desc"
          >
            <h3 className="optimal-stack-tagline">
              {truncate(tagline, characterLimit)}
            </h3>
          </TitleAnimation>
        )}
        {description?.data?.description && (
          <MarkDown className="optimal-stack-description">
            {description.data.description}
          </MarkDown>
        )}
      </div>
      <div className="optimal-stack-tech-wrapper">
        <div className="optimal-stack-inner-stack-wrapper">
          {stackTitle && (
            <p className="optimal-stack-inner-title">{stackTitle}</p>
          )}
          <div className="optimal-stack-inner-icons-wrapper">
            <TechnologyIcons
              className="optimal-stack-inner-icons"
              icons={technologies}
            />
          </div>
        </div>
        <div className="optimal-stack-inner-tools-wrapper">
          {toolsTitle && (
            <p className="optimal-stack-inner-title">{toolsTitle}</p>
          )}
          <div className="optimal-stack-inner-icons-wrapper">
            <TechnologyIcons
              className="optimal-stack-inner-icons"
              icons={technologyTools}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export { OptimalStack };
