import React from 'react';
import { BannerContent } from 'components';
import { SVG_BANNER } from 'types';

function SvgBanner({ banner, bannerBgSvg, pageHeading }: SVG_BANNER) {
  return (
    <div className="banner-wrapper">
      <img
        src={bannerBgSvg}
        alt="bannerBgSvg animation"
        className="banner-background-svg"
      />
      <div className="container">
        <div
          className={
            bannerBgSvg ? 'bannerbgsvg banner-section' : ' banner-section'
          }
        >
          <BannerContent banner={banner} pageHeading={pageHeading} />
        </div>
      </div>
    </div>
  );
}

export { SvgBanner };
