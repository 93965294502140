/* eslint-disable react/require-default-props */
/* eslint-disable react/function-component-definition */
import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
interface SlideUpPageContentProps {
  children: React.ReactNode;
  className?: string;
}
export const SlideUpPageContent: FC<SlideUpPageContentProps> = ({
  children,
  className,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    const ParagraphAnimationPosition =
      window.innerWidth < 576 ? 'top center+=220' : 'top center+=130';
    const element: any = ref.current;
    gsap.fromTo(
      ref.current,
      { y: 100, opacity: 0 },
      {
        duration: 1,
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: element,
          start: ParagraphAnimationPosition,
          toggleActions: 'play none none reverse',
        },
      }
    );
  }, [ref.current]);
  return (
    <div ref={ref} className={className ?? className}>
      {children}
    </div>
  );
};
