import React from 'react';
import Rocket from 'assets/svg/Rocket.svg';
import { TitleAnimation } from 'components/Animation';
import { CtaButton } from 'components';
import { LAUNCH_SECTION_PROP } from 'types';

function Launch({
  launch,
  durationTime = 1,
  delaySec = 0,
}: LAUNCH_SECTION_PROP) {
  const {
    title = '',
    description = {
      data: {
        description: '',
      },
    },
    ctaText = '',
    ctaLink = '',
    active,
  } = launch;
  if (!active) {
    return null;
  }
  return (
    <div className="work-container">
      <div className="launch">
        <div className="launch-wrapper">
          <div className="launch-content">
            {title && (
              <TitleAnimation durationTime={durationTime} delaySec={delaySec}>
                <h2 className="launch-title">{title}</h2>
              </TitleAnimation>
            )}
            {description && (
              <div className="launch-description">
                {description.data.description}
              </div>
            )}
            {ctaText && ctaLink && (
              <div className="header-button">
                <CtaButton
                  ctaTitle={ctaText}
                  ctaLink={`${ctaLink}`}
                  className="header-button-link"
                />
              </div>
            )}
          </div>
          <div className="launch-svg">
            <Rocket />
          </div>
        </div>
      </div>
    </div>
  );
}

export { Launch };
