/* eslint-disable react/function-component-definition */
import React from 'react';
import { BANNER_CONTENT } from 'types';
import { BannerContent } from './BannerContent';

const Banner = ({ pageHeading, banner, children }: BANNER_CONTENT) => {
  return (
    <div className="banner-section">
      <div className="banner-section-image">{children}</div>
      <BannerContent banner={banner} pageHeading={pageHeading} />
    </div>
  );
};
export { Banner };
