import React from 'react';
import ReactMarkdown from 'react-markdown';

interface MarkDownProps {
  context?: 'heading' | 'content';
  children: string;
  className?: string;
}

function MarkDown({ context, children, className }: MarkDownProps) {
  const headingTypesAllowed = ['text', 'strong', 'delete', 'emphasis', 'link'];
  if (context === 'heading') {
    return (
      <ReactMarkdown
        allowedElements={context === 'heading' ? headingTypesAllowed : []}
        unwrapDisallowed
        className={className}
      >
        {children}
      </ReactMarkdown>
    );
  }
  return (
    <ReactMarkdown className={`md-html ${className}`}>{children}</ReactMarkdown>
  );
}

MarkDown.defaultProps = {
  context: 'content',
  className: '',
};

export { MarkDown };
