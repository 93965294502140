/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import { Link } from 'gatsby';
import React from 'react';

export interface CTA_BUTTON_PROP {
  ctaTitle: string;
  ctaLink: string;
  className?: string;
  callBack?: () => void;
}

function CtaButton(props: CTA_BUTTON_PROP) {
  const { ctaLink, ctaTitle, className, callBack } = props;
  return (
    <>
      {ctaTitle && ctaLink && (
        <Link
          to={ctaLink}
          className={`btn-wrapper ${className}`}
          onClick={callBack && callBack}
        >
          <span className="btn-wrapper-inner">{ctaTitle}</span>
        </Link>
      )}
    </>
  );
}

export { CtaButton };
