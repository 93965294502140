import React from 'react';
import { Link } from 'gatsby';
import { MarkDown } from 'components';

function TextBanner({ data, modalTrigger }: any) {
  return (
    <div className="text-banner">
      <div className="text-banner-wrapper">
        <h2 className="text-banner-title">{data.title}</h2>
        <MarkDown className="text-banner-description">{data.tagline}</MarkDown>
        {data?.isLink ? (
          <Link
            to={data?.ctaLink || ''}
            className=" text-banner-cta btn-wrapper"
          >
            <span className="btn-wrapper-inner">{data.ctaText}</span>
          </Link>
        ) : (
          <button className="btn-wrapper" type="button" onClick={modalTrigger}>
            <span className="btn-wrapper-inner">{data.ctaText}</span>
            <span className="btn-wrapper-icon" />
          </button>
        )}
      </div>
    </div>
  );
}

export { TextBanner };
