/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import React, { useRef, useState } from 'react';
import Attach from 'assets/svg/link-svg.svg';

interface FileInputProps {
  register: any;
  buttonText: string;
  Required?: boolean;
  requiredMessage?: string;
}
export const FileInput = ({
  register,
  buttonText,
  Required = false,
  requiredMessage = '',
}: FileInputProps) => {
  const { ref, ...fields } = register('attachedFile', {
    required: {
      value: Required,
      message: requiredMessage,
    },
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [uploadedFile, setUploadedFile]: any = useState(null);
  const handleClick = () => {
    inputRef.current!.click();
  };

  return (
    <div className="form-attach-btn">
      <input
        type="file"
        style={{ display: 'none' }}
        {...fields}
        ref={instance => {
          ref(instance);
          inputRef.current = instance;
        }}
        onChange={e => {
          setUploadedFile(e.target.files);
        }}
        accept="application/pdf"
      />

      <Attach />
      <span>
        {uploadedFile && uploadedFile.length
          ? uploadedFile[0].name
          : buttonText}
      </span>
      <button type="button" onClick={handleClick}>
        Browse
      </button>
    </div>
  );
};
