import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import classNames from 'classnames';
import Linkedin from 'assets/svg/roundlinkedin.svg';
import Mail from 'assets/svg/roundmail.svg';
import Facebook from 'assets/svg/roundfacebook.svg';
import { CtaButton, CTALink } from 'components';
import { FooterQuery } from 'Queries/Footer';
import { comparePath } from 'utils';
import { NAV, MENU_PROP } from 'types';

function Menu({ mainNav, setIsMenuOpen }: MENU_PROP) {
  const { pathname } = useLocation();
  const menuItemHandler = () => {
    setIsMenuOpen(false);
  };
  const [path, setPath] = useState('');
  const FooterMenu = FooterQuery();
  const { linkedInLink, facebookLink, mailLink } =
    FooterMenu.strapiGlobal.footerComponent;

  useEffect(() => {
    setPath(pathname);
  }, [pathname]);
  return (
    <div className="menu-list-wrapper">
      <ul className="menu-list">
        {mainNav?.length > 0 &&
          mainNav.map((nav: NAV) => {
            if (nav.active) {
              return nav.highlight ? (
                <li className="menu-list-button" key={`menu-${nav.link}`}>
                  {nav?.title && nav.link && (
                    <div className="header-button">
                      <CtaButton
                        ctaTitle={nav.title}
                        ctaLink={nav.link}
                        callBack={menuItemHandler}
                      />
                    </div>
                  )}
                </li>
              ) : (
                <li
                  className={classNames('menu-list-item', {
                    active: comparePath(path, nav.link),
                  })}
                  key={nav.title}
                >
                  {nav?.title && nav.link && (
                    <CTALink ctaLink={nav.link} callBack={menuItemHandler}>
                      {nav.title}
                    </CTALink>
                  )}
                </li>
              );
            }
            return null;
          })}
        <li className="social-icons">
          <ul>
            <li>
              {linkedInLink && (
                <CTALink ctaLink={linkedInLink}>
                  <Linkedin />
                </CTALink>
              )}
            </li>

            <li>
              {mailLink && (
                <a href={`mailto:${mailLink}`} rel="noreferrer">
                  <Mail />
                </a>
              )}
            </li>

            <li>
              {facebookLink && (
                <CTALink ctaLink={facebookLink}>
                  <Facebook />
                </CTALink>
              )}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export { Menu };
