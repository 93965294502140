/* eslint-disable no-shadow */
import React from 'react';
import {
  CtaButton,
  OurWorkMobile,
  OurWorkSlider,
  SectionHeading,
} from 'components';
import { OUR_WORK_SECTION_PROP } from 'types';

function OurWorkComponent({ data }: OUR_WORK_SECTION_PROP) {
  const {
    title = '',
    workSummary = '',
    ctaText = '',
    ctaLink = '',
    caseStudySlider,
  } = data;
  return (
    <div className="container ourWork">
      <SectionHeading heading={title} tagline={workSummary} />
      <OurWorkMobile caseStudySlider={caseStudySlider} ctaLink={ctaLink} />
      <OurWorkSlider caseStudySlider={caseStudySlider} ctaLink={ctaLink} />
      {ctaText && ctaLink && (
        <div className="header-button findout-button">
          <CtaButton ctaTitle={ctaText} ctaLink={ctaLink} />
        </div>
      )}
    </div>
  );
}

export { OurWorkComponent };
