import React, { useEffect, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import Mouse from 'assets/svg/mouse.svg';
import { TitleAnimation } from 'components/Animation';
import { MarkDown, TechnologyIcons } from 'components';
import { PROJECT_BANNER_PROP, PROJECT_BANNER_IMAGE } from 'types';

function ProjectdetailBanner({
  banner,
  data,
  projectTitle,
  durationTime = 1,
  delaySec = 0,
}: PROJECT_BANNER_PROP) {
  const {
    title = '',
    tagline = '',
    description = {
      data: {
        description: '',
      },
    },
    bannerImage,
    technologies,
  } = data || {};
  const [scrollMouse, setScrollMouse] = useState(false);
  const scrollToBottom = () => {
    window.scrollTo(0, 900);
  };
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollMouse(window.scrollY > 50);
    });
  }, []);

  return (
    <div className="projectbanner">
      <div className="projectbanner-wrapper">
        <div className="projectbanner-detail">
          {title && (
            <TitleAnimation durationTime={durationTime} delaySec={delaySec}>
              <h1 className="projectbanner-detail-title">{title}</h1>
            </TitleAnimation>
          )}
          {tagline && <p className="projectbanner-detail-tagline">{tagline}</p>}
          {description?.data?.description && (
            <MarkDown className="projectbanner-detail-para">
              {description.data.description}
            </MarkDown>
          )}
          <TechnologyIcons icons={technologies} title="Technology Stack" />
          <button
            type="button"
            onClick={scrollToBottom}
            className={classNames('banner-section-mouse-icon', {
              scrolled: scrollMouse,
            })}
          >
            <Mouse />
            {banner?.ctaTitle ? (
              <span>{banner.ctaTitle}</span>
            ) : (
              <span>Scroll down</span>
            )}
          </button>
        </div>
        <div className="projectbanner-img-wrapper">
          <div className={`projectbanner-img ${projectTitle}`}>
            {bannerImage?.length > 0 &&
              bannerImage.map((item: PROJECT_BANNER_IMAGE, i: number) => {
                const { image } = item;
                const Image =
                  image && image.localFile
                    ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
                    : null;
                return (
                  <div
                    className={`img img-${i + 1}`}
                    key={image?.alternativeText || `${i + 1}`}
                  >
                    {Image && (
                      <GatsbyImage
                        image={Image}
                        alt={image?.alternativeText || title}
                      />
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
export { ProjectdetailBanner };
