import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MarkDown } from 'components';
import Logo from 'assets/svg/sectionlogo.svg';
import VideoDisplay from 'assets/images/videodisplaythuder.png';
import { TitleAnimation } from 'components/Animation';
import { WORK_CHALLENGE_SOLUTION_PROP, VIDEO_IMAGE } from 'types';

export function WorkChallengeSolutionResult({
  data,
  durationTime = 1,
  delaySec = 0,
}: WORK_CHALLENGE_SOLUTION_PROP) {
  return (
    <div className="work-container">
      <div className="work-challenge-solution-wrapper">
        <div
          key={data?.title || ''}
          className={`work-challenge-solution-item ${
            data.title.includes('Result') ? 'result' : 'solution'
          }`}
        >
          {data?.title && (
            <>
              <Logo />
              <TitleAnimation durationTime={durationTime} delaySec={delaySec}>
                <h3 className="work-challenge-solution-item-title">
                  {data.title}
                </h3>
              </TitleAnimation>
            </>
          )}
          {data?.summary?.data?.summary && (
            <div
              className={`work-challenge-solution-item-description ${
                data?.summaryLayout || ''
              }`}
            >
              <MarkDown>{data.summary.data.summary}</MarkDown>
            </div>
          )}

          <div
            className={`work-challenge-solution-item-images ${
              data?.imageLayout || ''
            }`}
          >
            {data?.videoImage?.length > 0 &&
              data.videoImage.map(
                ({ image, video }: VIDEO_IMAGE, i: number) => {
                  const Image =
                    image && image?.localFile
                      ? getImage(
                          image?.localFile?.childImageSharp?.gatsbyImageData
                        )
                      : null;
                  return (
                    <div
                      className={`work-challenge-solution-item-img work-challenge-solution-item-img-${
                        i + 1
                      }`}
                      key={image?.alternativeText || `${i + 1}`}
                    >
                      {video
                        ? video?.localFile?.publicURL && (
                            <>
                              <video
                                preload="auto"
                                autoPlay
                                loop
                                playsInline
                                poster={video.localFile.publicURL}
                                muted
                                controls={false}
                                className="video"
                              >
                                <source
                                  src={video?.localFile.publicURL}
                                  type="video/mp4"
                                />
                              </video>

                              <img
                                className="mediaframe"
                                src={VideoDisplay}
                                alt="videodisplay"
                              />
                            </>
                          )
                        : Image && (
                            <div className="work-challenge-solution-item-img-wrapper">
                              <GatsbyImage
                                image={Image}
                                alt={image?.alternativeText || data?.title}
                              />
                            </div>
                          )}
                    </div>
                  );
                }
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
