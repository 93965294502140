/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import Icons from 'assets/Icons/Icons.svg';
import { TECHNOLOGY_ICON_PROP, LOGO } from 'types';

export function TechnologyIcons({ icons, title }: TECHNOLOGY_ICON_PROP) {
  if (!icons || !icons.length) {
    return null;
  }
  return (
    <>
      {title && <h2 className="technology-list-title">{title}</h2>}
      <ul className="technology-list">
        {icons?.length > 0 &&
          icons.map((item: LOGO) => {
            return (
              <li className="technology-list-icons" key={item.title}>
                {item?.logo && (
                  <svg className={`icon-logo icon-${item.logo}`}>
                    <use xlinkHref={`${Icons}#icon-${item.logo}`} />
                  </svg>
                )}
                {item?.title && <p>{item.title}</p>}
              </li>
            );
          })}
      </ul>
    </>
  );
}
