import React from 'react';
import { MarkDown, SectionHeading } from 'components';
import { MEET_OUR_TEAM_PROP } from 'types';

function MeetOurTeam({ meetOurTeam }: MEET_OUR_TEAM_PROP) {
  const {
    title = '',
    tagline = { data: { tagline: '' } },
    summary = { data: { summary: '' } },
  } = meetOurTeam;
  // TODO get heading from CMS

  return (
    <div className="meetourteam">
      <div className="meetourteam-section-logo">
        <SectionHeading heading="About Us" tagline={title} />
      </div>
      <div className="meetourteam-section-content">
        {tagline?.data?.tagline && (
          <div className="meetourteam-section-tagline">
            <h3>
              <MarkDown>{tagline.data.tagline}</MarkDown>
            </h3>
          </div>
        )}
        {summary?.data?.summary && (
          <div className="meetourteam-section-summary">
            <MarkDown>{summary.data.summary}</MarkDown>
          </div>
        )}
      </div>
    </div>
  );
}

export { MeetOurTeam };
