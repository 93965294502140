/* eslint-disable no-shadow */
import React from 'react';
import Design from 'assets/svg/service-design.svg';
import Develop from 'assets/svg/webdevelopment.svg';
import Planning from 'assets/svg/service-planning.svg';
import Testing from 'assets/svg/service-testing.svg';
import Deployment from 'assets/svg/service-deployment.svg';
import Logo from 'assets/svg/sectionlogo.svg';
import { TitleAnimation, TimelineAnimation } from 'components/Animation';
import { SERVICE_ICON_SECTION_PROP, SERVICE_ICON } from 'types';

function ServiceIconSection({
  serviceIconsSection,
  durationTime = 1,
  delaySec = 0,
}: SERVICE_ICON_SECTION_PROP) {
  const {
    title = '',
    tagline = '',
    summary = {
      data: {
        summary: '',
      },
    },
    serviceIcon = [],
  } = serviceIconsSection;

  return (
    <div className="service-details-page-section-wrapper">
      <div className="service-details-page-wrapper">
        {title && (
          <>
            <div className="service-details-page-logo">
              <Logo />
            </div>
            <p className="service-details-page-title">{title}</p>
          </>
        )}
        {tagline && (
          <TitleAnimation durationTime={durationTime} delaySec={delaySec}>
            <p className="service-details-page-tagline">{tagline}</p>
          </TitleAnimation>
        )}
        {summary?.data?.summary && (
          <div className="service-details-page-summary">
            {summary.data.summary}
          </div>
        )}
      </div>
      <div className="service-details-page-icon-container-wrapper">
        <div className="service-details-page-icon-container">
          {serviceIcon?.length > 0 &&
            serviceIcon.map((item: SERVICE_ICON, index: number) => {
              const {
                icon = '',
                title = '',
                summary = {
                  data: {
                    summary: '',
                  },
                },
              } = item;
              const direction: string =
                (index + 1) % 2 === 0 ? 'right' : 'left';

              let processIcon;
              switch (icon) {
                case 'Design':
                  processIcon = <Design />;
                  break;
                case 'Develop':
                  processIcon = <Develop />;
                  break;
                case 'Planning':
                  processIcon = <Planning />;
                  break;
                case 'Testing':
                  processIcon = <Testing />;
                  break;
                case 'Deployment':
                  processIcon = <Deployment />;
                  break;
                default:
                  processIcon = <Design />;
                  break;
              }
              return (
                <TimelineAnimation
                  direction={direction}
                  className="service-details-page-icon-card"
                  key={title}
                >
                  <div className="service-details-page-wrapper-header">
                    <div className="service-details-page-sectionWrapper">
                      <div className="border-chain">
                        <span />
                      </div>
                      <div className="service-details-page-icon">
                        {processIcon}
                      </div>
                    </div>
                  </div>
                  {title && (
                    <h4 className="service-details-page-icon-title">{title}</h4>
                  )}
                  {summary?.data?.summary && (
                    <div className="service-details-page-icon-summary">
                      {summary.data.summary}
                    </div>
                  )}
                </TimelineAnimation>
              );
            })}
        </div>
        <div className="service-details-page-line">
          <span />
        </div>
      </div>
    </div>
  );
}

export { ServiceIconSection };
