import React from 'react';
import { CareerForm } from 'components';
import { TitleAnimation } from 'components/Animation';
import { CAREER_FORM_SECTION } from 'types';

export function CareerFormSection({
  data,
  positionList,
  selectedPosition,
  isFocused = false,
  formSuccessMessage = '',
  formErrorMessage,
  durationTime = 1,
  delaySec = 0,
  active = true,
}: CAREER_FORM_SECTION) {
  if (!active) {
    return null;
  }
  return (
    <div className="career-form-content">
      {data?.title && (
        <TitleAnimation durationTime={durationTime} delaySec={delaySec}>
          <h3 className="career-form-content-title">{data.title}</h3>
        </TitleAnimation>
      )}
      <div className="career-form-bggreen">
        <span />
      </div>
      {data?.summary?.data?.summary && (
        <p className="career-form-content-summary">
          {data.summary.data.summary}
        </p>
      )}
      <CareerForm
        positionList={positionList}
        selectedPosition={selectedPosition}
        isInputFocused={isFocused}
        formSuccessMessage={formSuccessMessage}
        formErrorMessage={formErrorMessage}
      />
    </div>
  );
}
