/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { BufferGeometry, BufferAttribute } from 'three';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import starTexture from '../../assets/images/star.png';

function ParticleMesh({ particlesCnt = 5000 }) {
  const particleRef = useRef<THREE.Points>(null!);
  const starImg = useLoader(TextureLoader, starTexture);
  const [mousePosition, setMousePosition] = useState({
    mouseX: 0,
    mouseY: 0,
  });

  const posArray = useMemo(() => {
    const array = new Float32Array(particlesCnt * 3);
    for (let i = 0; i < particlesCnt * 3; i++) {
      array[i] = (Math.random() - 0.5) * 150;
    }
    return array;
  }, [particlesCnt]);

  const particleGeometry = useMemo(() => {
    const geometry = new BufferGeometry();
    geometry.setAttribute('position', new BufferAttribute(posArray, 3));
    return geometry;
  }, [posArray]);

  useEffect(() => {
    if (particleRef?.current) {
      particleRef.current.rotation.x = mousePosition.mouseX * 0.0005;
      particleRef.current.rotation.y = -mousePosition.mouseY * 0.0005;
    }
  }, [mousePosition]);
  useFrame(() => {
    if (particleRef?.current) {
      particleRef.current.rotation.x += 0.0003;
      particleRef.current.rotation.y += 0.0003;
    }
  });

  useEffect(() => {
    function animateParticle(event: any) {
      setMousePosition({
        mouseX: event.clientX,
        mouseY: event.clientY,
      });
    }
    document.addEventListener('mousemove', animateParticle);
    return () => document.removeEventListener('mousemove', animateParticle);
  }, []);

  return (
    <points geometry={particleGeometry} ref={particleRef}>
      <pointsMaterial size={0.2} map={starImg} transparent />
    </points>
  );
}

function StarThreeBg() {
  const particlesCnt = 2000;

  useEffect(() => {}, []);
  return (
    <Canvas
      style={{ height: '100vh', position: 'fixed', top: 0, zIndex: -1 }}
      camera={{ fov: 20, near: 0.1, far: 1000, position: [0, 0, 50] }}
      className="star-bg-wrapper"
    >
      <ambientLight intensity={0.1} />
      <ParticleMesh particlesCnt={particlesCnt} />
    </Canvas>
  );
}
export { StarThreeBg };
