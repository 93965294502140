import React from 'react';

function StatisticsList({ data }: any) {
  return (
    <div className="statistics-list-wrapper">
      <ul className="statistics-list">
        {data?.Statistic?.length > 0 &&
          data.Statistic.map((item: any) => {
            return (
              <li className="statistics-list-item" key={item.id}>
                <p className="statistics-list-item-value">{item.value}</p>
                <p className="statistics-list-item-title">{item.title}</p>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
export { StatisticsList };
