/* eslint-disable react/function-component-definition */
/* eslint-disable react/require-default-props */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import React, { useEffect } from 'react';

gsap.registerPlugin(ScrollTrigger);
gsap.defaults({ ease: 'power3' });

export const CardAnimation = ({ children }: any) => {
  useEffect(() => {
    gsap.set('.box', { y: 100 });
    ScrollTrigger.batch('.box', {
      interval: 0.1,
      onEnter: batch =>
        gsap.to(batch, {
          opacity: 1,
          y: 0,
          stagger: { each: 0.15, grid: [1, 3] },
          overwrite: true,
        }),
      onLeave: batch =>
        gsap.set(batch, { opacity: 0, y: -100, overwrite: true }),
      onEnterBack: batch =>
        gsap.to(batch, { opacity: 1, y: 0, stagger: 0.15, overwrite: true }),
      onLeaveBack: batch =>
        gsap.set(batch, { opacity: 0, y: 100, overwrite: true }),
    });
    return () => {
      ScrollTrigger.addEventListener('refreshInit', () =>
        gsap.set('.box', { y: 0 })
      );
    };
  }, []);
  return <div>{children}</div>;
};
