import React from 'react';
import { Link } from 'gatsby';
import { FAQList } from 'components';

export function FaqTwoCol({ data, modalTrigger }: any) {
  return (
    <div className="faq-two-col">
      <div className="faq-two-col-content">
        <h2 className="faq-two-col-content-title">{data.title}</h2>
        {data?.isLink ? (
          <Link
            to={data?.ctaLink}
            className="faq-two-col-content-cta btn-wrapper"
          >
            <span className="btn-wrapper-inner">{data.ctaText}</span>
          </Link>
        ) : (
          <button
            className="btn-wrapper faq-two-col-content-cta"
            type="button"
            onClick={modalTrigger}
          >
            <span className="btn-wrapper-inner">{data.ctaText}</span>
            <span className="btn-wrapper-icon" />
          </button>
        )}
      </div>
      <div className="faq-two-col-list">
        <FAQList list={data.faqs} />
      </div>
    </div>
  );
}
