import { useStaticQuery, graphql } from 'gatsby';

const FooterQuery = () => {
  return useStaticQuery(graphql`
    query siteDataQuery {
      strapiGlobal {
        footerComponent {
          footerNavigation {
            title
            link
            highlight
            active
          }
          copyrightMessage
          facebookLink
          linkedInLink
          mailLink
          addresses {
            title
            summary {
              data {
                summary
              }
            }
          }
        }
      }
    }
  `);
};
export { FooterQuery };
