import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import Logo from 'assets/svg/section-logo.svg';
import ArrowNext from 'assets/svg/arrow-right.svg';
import ArrowPrev from 'assets/svg/arrow-left.svg';
import { REVIEWS_SECTION_PROP, REVIEWS_PROPS } from 'types';

export function Reviews({ data }: REVIEWS_SECTION_PROP) {
  return (
    <section className="reviews">
      <div className="container">
        {data.title && (
          <div className="section-logo">
            <Logo />
            <h2 className="section-title">{data.title}</h2>
          </div>
        )}
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          loop
          navigation={{
            prevEl: '.prev-btn',
            nextEl: '.next-btn',
          }}
          pagination={{
            type: 'fraction',
            el: '.reviews-pagination',
          }}
        >
          {data?.reviews?.length > 0 &&
            data.reviews.map((reviewItem: REVIEWS_PROPS) => {
              const { quote = '', title = '', role = '' } = reviewItem;
              return (
                <SwiperSlide key={title}>
                  <blockquote>
                    {quote && <p className="reviews-quote">{quote}</p>}
                  </blockquote>
                  <div className="reviews-author">
                    {title && <h4>{title}</h4>}
                    {role && <p>{role}</p>}
                  </div>
                </SwiperSlide>
              );
            })}
          <div className="reviews-navigation">
            <div className="prev-btn">
              <ArrowPrev />
            </div>
            <div className="reviews-pagination" />
            <div className="next-btn">
              <ArrowNext />
            </div>
          </div>
        </Swiper>
      </div>

    </section>
  );
}
