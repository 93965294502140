import { useStaticQuery, graphql } from 'gatsby';

const GetInTouchQuery = () => {
  return useStaticQuery(graphql`
    query GetIntouchQuery {
      strapiGlobal {
        getInTouchCtaLink
        getInTouchCtaText
        getInTouchTagline
        getInTouchTitle
      }
    }
  `);
};
export { GetInTouchQuery };
