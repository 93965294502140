/* eslint-disable no-shadow */
import React from 'react';
import { truncate } from 'utils';
import WebDevelopment from 'assets/svg/webdevelopment.svg';
import CloudComputing from 'assets/svg/cloudcomputing.svg';
import DomainHosting from 'assets/svg/domainhosting.svg';
import StaticDynamicWebsites from 'assets/svg/staticdynamicwebsites.svg';
import SiteOptimization from 'assets/svg/SiteOptimization.svg';
import WebDesign from 'assets/svg/WebDesign.svg';
import QATesting from 'assets/svg/qatesting.svg';
import SEO from 'assets/svg/searchengineoptimization.svg';
import Ecommerce from 'assets/svg/ecommerce.svg';
import Quality from 'assets/svg/Quality.svg';
import LatestTech from 'assets/svg/Latest-tech.svg';
import MobileDevelopment from 'assets/svg/mobiledevelopment.svg';
import EndToEnd from 'assets/svg/endtoendmaintenance.svg';
import HighSpeed from 'assets/svg/High-Speed-Performance.svg';
import ModernDesign from 'assets/svg/modern-responsive-designs.svg';
import CostEffective from 'assets/svg/cost-effective-solutions.svg';

import { SlideInAnimation } from 'components/Animation';
import { CTALink, MarkDown, SectionHeading } from 'components';
import { CARD_SECTION_PROP, OUR_SERVICES_LIST_ITEM, CORE_VALUE } from 'types';

function CardIconSection({
  cardDetails,
  cardTitle,
  cardDescription,
  showAnimation = false,
}: CARD_SECTION_PROP) {
  const characterLimit = 300;

  return (
    <>
      <div className="card-icon-section">
        <SectionHeading heading={cardTitle} tagline={cardDescription} />
      </div>
      <div className="card-icon-section-container">
        {cardDetails?.length > 0 &&
          cardDetails.map(
            (
              item: OUR_SERVICES_LIST_ITEM | CORE_VALUE | any,
              index: number
            ) => {
              const {
                title = '',
                description = {
                  data: {
                    description: '',
                  },
                },
                summary = {
                  data: { summary: '' },
                },
                ctaLink = '',
                ctaTitle = '',
                iconPosition = '',
              } = item;

              let processIcon;

              switch (title) {
                case 'Web Design & Application':
                  processIcon = <WebDevelopment />;
                  break;
                case 'Mobile Development':
                  processIcon = <MobileDevelopment />;
                  break;
                case 'Cloud Computing':
                  processIcon = <CloudComputing />;
                  break;
                case 'QA Testing':
                  processIcon = <QATesting />;
                  break;
                case 'End to End Maintenance':
                  processIcon = <EndToEnd />;
                  break;
                case 'Domain Hosting':
                  processIcon = <DomainHosting />;
                  break;
                case 'Quality':
                  processIcon = <Quality />;
                  break;
                case 'E-Commerce':
                  processIcon = <Ecommerce />;
                  break;
                case 'Static & Dynamic Websites':
                  processIcon = <StaticDynamicWebsites />;
                  break;
                case 'Site Optimization':
                  processIcon = <SiteOptimization />;
                  break;
                case 'Web-Design':
                  processIcon = <WebDesign />;
                  break;
                case 'Latest Technology':
                  processIcon = <LatestTech />;
                  break;
                case 'SEO':
                  processIcon = <SEO />;
                  break;
                case 'Modern & Responsive Designs':
                  processIcon = <ModernDesign />;
                  break;
                case 'Cost-Effective Solutions':
                  processIcon = <CostEffective />;
                  break;
                case 'High-Speed Performance':
                  processIcon = <HighSpeed />;
                  break;
                default:
                  processIcon = <WebDevelopment />;
                  break;
              }
              const direction = (index + 1) % 2 === 0 ? 'right' : 'left';
              return (
                <SlideInAnimation
                  direction={direction}
                  showAnimation={showAnimation}
                  className="card-icon-section-container-outer box"
                  key={title}
                >
                  {ctaLink ? (
                    <CTALink
                      ctaLink={ctaLink}
                      className={`card-icon-section-container-outer-link ${iconPosition}`}
                    >
                      <div className="card-icon-section-container-inner">
                        <div className="card-icon-section-container-inner-content">
                          {title && (
                            <p className="card-icon-section-container-inner-title">
                              {title}
                            </p>
                          )}
                          {(description?.data?.description ||
                            summary?.data?.summary) && (
                            <MarkDown className="card-icon-section-container-inner-description">
                              {truncate(
                                description?.data?.description ||
                                  summary?.data?.summary,
                                characterLimit
                              )}
                            </MarkDown>
                          )}
                        </div>
                        {ctaTitle && ctaLink && (
                          <span className="readmore-button-link">
                            <span className="readmore-button-text">
                              {ctaTitle}
                            </span>
                          </span>
                        )}
                      </div>
                      {processIcon && (
                        <div className="card-icon-section-container-svg-inner">
                          {processIcon} <span />
                        </div>
                      )}
                    </CTALink>
                  ) : (
                    <div
                      className={`card-icon-section-container-outer-link ${iconPosition}`}
                    >
                      <div className="card-icon-section-container-inner">
                        <div className="card-icon-section-container-inner-content">
                          {title && (
                            <p className="card-icon-section-container-inner-title">
                              {title}
                            </p>
                          )}
                          {(description?.data.description ||
                            summary?.data?.summary) && (
                            <MarkDown className="card-icon-section-container-inner-description">
                              {truncate(
                                description?.data.description ||
                                  summary?.data?.summary,
                                characterLimit
                              )}
                            </MarkDown>
                          )}
                        </div>
                        {ctaTitle && ctaLink && (
                          <CTALink
                            className="readmore-button-link"
                            ctaLink={ctaLink}
                          >
                            <span className="readmore-button-text">
                              {ctaTitle}
                            </span>
                          </CTALink>
                        )}
                      </div>
                      {processIcon && (
                        <div className="card-icon-section-container-svg-inner">
                          {processIcon} <span />
                        </div>
                      )}
                    </div>
                  )}
                </SlideInAnimation>
              );
            }
          )}
      </div>
    </>
  );
}

export { CardIconSection };
