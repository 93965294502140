import React from 'react';
import Logo from 'assets/svg/sectionlogo.svg';
import Ellipse from 'assets/svg/ellipse.svg';
import { GetInTouchQuery } from 'Queries/GetInTouch';
import { CtaButton, SectionHeading } from 'components';

export function GetInTouch() {
  const {
    strapiGlobal: {
      getInTouchCtaLink,
      getInTouchCtaText,
      getInTouchTagline,
      getInTouchTitle,
    },
  } = GetInTouchQuery();
  const contactBtnText = 'Contact us';
  const taglineText = 'Level up your game';
  return (
    <section className="get-in-touch-section">
      <div className="get-in-touch-wrapper">
        <SectionHeading
          heading={contactBtnText}
          tagline={getInTouchTitle}
          animatedTitle={false}
        />
        <p className="get-in-touch-tagline">{getInTouchTagline}</p>
        <div className="header-button get-in-touch">
          <CtaButton
            ctaLink={`/${getInTouchCtaLink}`}
            ctaTitle={getInTouchCtaText}
          />
        </div>
        <div className="tagline-wrapper">
          <Ellipse />
          <p className="tagline">{taglineText}</p>
        </div>
      </div>
    </section>
  );
}
