import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { MarkDown } from 'components';

function TwoColContentImage({ data, modalTrigger }: any) {
  const ImageItem =
    data.image && data.image?.localFile
      ? getImage(data?.image?.localFile?.childImageSharp?.gatsbyImageData)
      : null;

  return (
    <div className="two-col-content-image">
      <div className="two-col-content">
        <h2 className="two-col-content-title">{data.title}</h2>
        <MarkDown className="two-col-content-description ">
          {data?.description?.data?.description}
        </MarkDown>
        {data?.isLink ? (
          <Link
            to={data?.ctaLink || ''}
            className=" two-col-content-cta btn-wrapper"
          >
            <span className="btn-wrapper-inner">{data.ctaText}</span>
          </Link>
        ) : (
          <button
            className="two-col-content-cta btn-wrapper"
            type="button"
            onClick={modalTrigger}
          >
            <span className="btn-wrapper-inner">{data.ctaText}</span>
            <span className="btn-wrapper-icon" />
          </button>
        )}
      </div>
      <div className="two-col-image">
        {ImageItem && (
          <GatsbyImage
            image={ImageItem}
            alt={data.image?.alternativeText || data?.title}
            objectFit="contain"
          />
        )}
      </div>
    </div>
  );
}

export { TwoColContentImage };
