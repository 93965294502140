/* eslint-disable react/jsx-no-useless-fragment */
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { CTALink, MarkDown } from 'components';
import { CASE_STUDY_SLIDER_PROP, CASE_STUDY_SLIDER } from 'types';

function OurWorkMobile({ caseStudySlider, ctaLink }: CASE_STUDY_SLIDER_PROP) {
  const slicedCaseStudies = caseStudySlider?.slice(0, 3);
  return (
    <div className="our-work-card-wrapper our-work-card-wrapper-mobile">
      {slicedCaseStudies?.length > 0 &&
        slicedCaseStudies.map((item: CASE_STUDY_SLIDER) => {
          const {
            title = '',
            sliderImage = null,
            slug = '',
            shortDescription = {
              data: {
                shortDescription: '',
              },
            },
          } = item.case_study;
          const Image =
            sliderImage && sliderImage?.localFile
              ? getImage(
                  sliderImage?.localFile?.childImageSharp?.gatsbyImageData
                )
              : null;
          return (
            <div className="our-work-card-container" key={title}>
              {title && shortDescription && (
                <CTALink ctaLink={`${ctaLink}/${slug}`}>
                  <div className="our-work-card">
                    <div className="our-work-card-details">
                      <div className="our-work-card-details-wrapper">
                        {Image && (
                          <div className="our-work-card-img">
                            <GatsbyImage
                              image={Image}
                              alt={sliderImage?.alternativeText || title}
                            />
                          </div>
                        )}
                      </div>
                      <MarkDown className="our-work-card-description">
                        {shortDescription?.data?.shortDescription}
                      </MarkDown>
                    </div>
                  </div>
                </CTALink>
              )}
            </div>
          );
        })}
    </div>
  );
}

export { OurWorkMobile };
