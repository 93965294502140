import React from 'react';

import Location from 'assets/svg/location-icon.svg';
import Experience from 'assets/svg/experience-icon.svg';
import Salary from 'assets/svg/salary-icon.svg';
import { TechnologyIcons, MarkDown } from 'components';
import { POSITION_DETAILS } from 'types';

export function PositionDetails({ data, handlePopupCta }: POSITION_DETAILS) {
  const { additionalSkills, requiredSkills } = data;

  return (
    <section className="position-detail">
      {data?.title && <h1 className="position-detail-title">{data.title}</h1>}
      <div className="position-detail-feature">
        {data?.experience && (
          <div className="position-detail-feature-item">
            <div className="position-detail-feature-item-icon">
              <Salary />
            </div>
            <div className="position-detail-feature-item-text">
              <p className="position-detail-feature-item-text-value">
                {data.experience}
              </p>
              <p className="position-detail-feature-item-text-label">
                Experience
              </p>
            </div>
          </div>
        )}

        {data?.salary && (
          <div className="position-detail-feature-item">
            <div className="position-detail-feature-item-icon">
              <Experience />
            </div>
            <div className="position-detail-feature-item-text">
              <p className="position-detail-feature-item-text-value">
                {data.salary}
              </p>
              <p className="position-detail-feature-item-text-label">
                Annual Salary
              </p>
            </div>
          </div>
        )}

        {data?.location && (
          <div className="position-detail-feature-item">
            <div className="position-detail-feature-item-icon">
              <Location />
            </div>
            <div className="position-detail-feature-item-text">
              <p className="position-detail-feature-item-text-value">
                {data.location}
              </p>
              <p className="position-detail-feature-item-text-label">
                Location
              </p>
            </div>
          </div>
        )}
      </div>
      {data?.summary?.data && (
        <p className="position-detail-desc">
          <MarkDown>{data.summary.data}</MarkDown>
        </p>
      )}

      {data?.requirements?.data && (
        <div className="position-detail-requirement">
          <h4>What we are looking for</h4>
          <MarkDown>{data.requirements.data}</MarkDown>
        </div>
      )}
      {data?.responsibilities?.data && (
        <div className="position-detail-responsibilities">
          <h4>Responsibilities</h4>
          <MarkDown>{data.responsibilities.data}</MarkDown>
        </div>
      )}

      <div className="position-detail-skills">
        <h4>Skills</h4>
        <div className="skills">
          <p>Required Skills*</p>
          <div className="position-detail-skills-wrapper">
            <TechnologyIcons
              className="optimal-stack-inner-icons"
              icons={requiredSkills}
            />
          </div>
        </div>
        <div className="skills">
          <p>Additional Skills</p>
          <div className="position-detail-skills-wrapper">
            <TechnologyIcons
              className="optimal-stack-inner-icons"
              icons={additionalSkills}
            />
          </div>
        </div>
      </div>
      <div className="position-detail-cta-button">
        <button
          className="btn-wrapper"
          type="button"
          onClick={() => handlePopupCta()}
        >
          <span className="btn-wrapper-inner">Apply Now</span>
          <span className="btn-wrapper-icon" />
        </button>
      </div>
    </section>
  );
}
