import { useStaticQuery, graphql } from 'gatsby';

const MenuQuery = () => {
  return useStaticQuery(graphql`
    query MenuQuery {
      strapiGlobal {
        navigation {
          navItem {
            link
            title
            active
            highlight
          }
        }
      }
    }
  `);
};
export { MenuQuery };
