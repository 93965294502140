import React from 'react';
import { MarkDown } from 'components';

export function FAQList({ list }: any) {
  return (
    <ul className="faq-list">
      {list?.length > 0 &&
        list.map((item: any) => {
          return (
            <li className="faq-list-item" key={item.id}>
              <h6 className="faq-list-item-title">{item.title}</h6>
              <MarkDown className="faq-list-item-description">
                {item?.summary?.data?.summary}
              </MarkDown>
            </li>
          );
        })}
    </ul>
  );
}
